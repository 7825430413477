import pages from '~/api/services/pages'
import authentication from '~/api/services/authentication'
import general from '~/api/services/general'
import user from '~/api/services/user'
import create from '~/api/services/create'
import messages from '~/api/services/messages'
import notifications from '~/api/services/notifications'
import info from '~/api/services/info'

const modules = {
    authentication,
    pages,
    general,
    user,
    create,
    messages,
    notifications,
    info,
}

export { modules }
