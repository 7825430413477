
import Vue, { PropType } from 'vue'
import { Models } from '~/types/models'

export default Vue.extend({
    name: 'LayoutHeaderAdBars',
    props: {
        adbars: {
            type: [Object, Boolean] as PropType<Models.AdBars | false>,
            required: true,
        },
    },
})
