
import Vue from 'vue'
import { Models } from '~/types/models'

export default Vue.extend({
    name: 'MicroArcLinks',

    data() {
        const headband = this.$store.state.headband as Models.Link[]
        return {
            headband,
            skeletons: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
        }
    },
})
