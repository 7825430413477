export default {
    home: {
        path: '/v1/pages/home',
        featured: '/v1/pages/home/featureds',
        latest: '/v1/pages/home/news',
        commented: '/v1/pages/home/commented',
    },
    coupons: {
        path: '/v1/pages/coupons',
    },
    offers: {
        path: '/v1/pages/offers',
    },
    offer: {
        path: '/v1/offers',
    },
    coupon: {
        path: '/v1/coupons',
    },
    lotteries: {
        path: '/v1/pages/lotteries',
    },
    lottery: {
        path: '/v1/lotteries',
    },
    forum: {
        path: '/v1/pages/chats',
    },
    thread: {
        path: '/v1/forums',
    },
    blog: {
        path: '/v1/pages/blog',
    },
    articlesByCategory: {
        path: '/v1/articles-by-category',
    },
    articles: {
        path: '/v1/articles',
    },
    categories: {
        path: '/v1/pages/categories',
    },
    category: {
        path: '/v1/categories',
        secondaryData: '/v1/categories/_SLUG_/others',
    },
    free: {
        path: '/v1/pages/free',
    },
    user: {
        path: '/v1/user/_ID_/profile',
    },
    events: {
        path: '/v1/pages/events',
    },
    event: {
        path: '/v1/events',
    },
    stores: {
        path: '/v1/pages/stores',
        lp: '/v1/stores/_SLUG_/lp-status',
    },
    store: {
        path: '/v1/stores/_SLUG_',
        secondaryData: '/v1/stores/_SLUG_/others',
        expired: '/v1/stores/_SLUG_/expired',
    },
    novelties: {
        path: '/v1/pages/news',
    },
    novelty: {
        path: '/v1/novelties',
    },
    search: {
        path: '/v1/pages/search',
    },
    login: {
        path: '/v1/pages/login',
    },
    register: {
        path: '/v1/pages/register',
    },
    siteMap: {
        categories: '/v1/site-map/categories',
        stores: '/v1/site-map/stores',
        discounts: '/v1/site-map/discounts',
    },
}
