import { Context } from '@nuxt/types'

export default function ({ route, redirect }: Context) {
    if (route.path !== '/' && route.path.endsWith('/')) {
        const { path, query, hash } = route
        const nextPath = path.replace(/\/+$/, '') || '/'
        const nextRoute = { path: nextPath, query, hash }

        redirect(301, nextRoute)
    }
}
