import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { AxiosError } from 'axios'
import endpoints from '@/api/endpoints/messages'

export default (AxiosInstance: NuxtAxiosInstance) => ({
    loadConversations: (query?: object) =>
        AxiosInstance.$get(endpoints.conversations.path, { params: query }).catch((e: AxiosError) => {
            return e.response?.data
        }),
    conversationDetail: (id: number, query?: object) =>
        AxiosInstance.$get(endpoints.conversationDetail.path + '/' + id, { params: query }).catch(
            (e: AxiosError) => {
                return e.response?.data
            }
        ),
    createConversation: (payload: { receiver_id: number; message: string }) =>
        AxiosInstance.$post(endpoints.conversationDetail.path, payload).catch((e: AxiosError) => {
            return e.response?.data
        }),
    createMessage: (payload: { conversation_id: number; message: string }) =>
        AxiosInstance.$post(endpoints.create.path, payload).catch((e: AxiosError) => {
            return e.response?.data
        }),
    markAsRead: (id: string) =>
        AxiosInstance.$get(endpoints.markAsRead.path.replace('_ID_', id)).catch((e: AxiosError) => {
            return e.response?.data
        }),
    deleteConversation: (id: number) =>
        AxiosInstance.$delete(endpoints.conversationDetail.path + '/' + id).catch((e: AxiosError) => {
            return e.response?.data
        }),
    silentConversation: (id: number, silent: boolean) => {
        const silentPath = silent ? '/silent' : '/un-silent'
        return AxiosInstance.$get(endpoints.conversationDetail.path + '/' + id + silentPath).catch(
            (e: AxiosError) => {
                return e.response?.data
            }
        )
    },
})
