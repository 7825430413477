import { AxiosError } from 'axios'
import { NuxtAxiosInstance } from '@nuxtjs/axios'
import endpoints from '@/api/endpoints/create'

export default (AxiosInstance: NuxtAxiosInstance) => ({
    postCoupons: (payload: object) =>
        AxiosInstance.$post(endpoints.coupons.path, payload).catch((e: AxiosError) => e.response?.data),
    postOffers: (payload: object) =>
        AxiosInstance.$post(endpoints.offers.path, payload).catch((e: AxiosError) => e.response?.data),
    postChat: (payload: object) =>
        AxiosInstance.$post(endpoints.foros.path, payload).catch((e: AxiosError) => e.response?.data),
    postLottery: (payload: object) =>
        AxiosInstance.$post(endpoints.lotteries.path, payload).catch((e: AxiosError) => e.response?.data),
    postVote: (payload: object) =>
        AxiosInstance.$post(endpoints.votes.path, payload).catch((e) => e.response?.data),
    postComment: (payload: object) =>
        AxiosInstance.$post(endpoints.comments.path, payload).catch((e) => e.response?.data),
    postLike: (payload: object) =>
        AxiosInstance.$post(endpoints.likes.path, payload).catch((e) => e.response?.data),
    postReport: (payload: object) =>
        AxiosInstance.$post(endpoints.report.path, payload).catch((e) => e.response?.data),
    postExpire: (payload: object) =>
        AxiosInstance.$post(endpoints.expire.path, payload).catch((e) => e.response?.data),
    savePost: (payload: object) =>
        AxiosInstance.$post(endpoints.save.path, payload).catch((e) => e.response?.data),
    codeWorks: (id: string, payload: object) =>
        AxiosInstance.$post(endpoints.works.path.replace('_ID_', id), payload).catch((e) => e.response?.data),
})
