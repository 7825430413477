const middleware = {}

middleware['ifAcceptTerms'] = require('../middleware/ifAcceptTerms.ts')
middleware['ifAcceptTerms'] = middleware['ifAcceptTerms'].default || middleware['ifAcceptTerms']

middleware['ifAuthenticated'] = require('../middleware/ifAuthenticated.ts')
middleware['ifAuthenticated'] = middleware['ifAuthenticated'].default || middleware['ifAuthenticated']

middleware['ifNotAuthenticated'] = require('../middleware/ifNotAuthenticated.ts')
middleware['ifNotAuthenticated'] = middleware['ifNotAuthenticated'].default || middleware['ifNotAuthenticated']

middleware['redirects'] = require('../middleware/redirects.ts')
middleware['redirects'] = middleware['redirects'].default || middleware['redirects']

middleware['trailingSlash'] = require('../middleware/trailingSlash.ts')
middleware['trailingSlash'] = middleware['trailingSlash'].default || middleware['trailingSlash']

export default middleware
