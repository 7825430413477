
import Vue from 'vue'

export default Vue.extend({
    name: 'SidebarGiveMe',
    computed: {
        apps(): { android: string | null; apple: string | null } | null {
            return this.$store.state.layout?.sidebar.apps
        },
    },
})
