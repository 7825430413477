
import Vue, { PropOptions } from 'vue'
import { Models } from '~/types/models'

export default Vue.extend({
    name: 'MicroUserImage',
    props: {
        info: {
            type: Object,
            required: true,
        } as PropOptions<Models.User>,
        badge: {
            type: Boolean,
            default: true,
        },
        notifications: {
            type: Number,
            default: null,
        },
    },
    data() {
        return {
            fallback: null as null | string,
        }
    },
})
