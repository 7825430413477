import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { AxiosError } from 'axios'
import endpoints from '@/api/endpoints/notifications'

export default (AxiosInstance: NuxtAxiosInstance) => ({
    markAsRead: (payload: object) =>
        AxiosInstance.$post(endpoints.MarkAsRead.path, payload).catch((e: AxiosError) => {
            return e.response?.data
        }),
    muteAction: (payload: object) =>
        AxiosInstance.$post(endpoints.muteAction.path, payload).catch((e: AxiosError) => {
            return e.response?.data
        }),
    muteModel: (payload: object) =>
        AxiosInstance.$post(endpoints.muteModel.path, payload).catch((e: AxiosError) => {
            return e.response?.data
        }),
    delete: (id: number) =>
        AxiosInstance.$delete(endpoints.delete.path + '/' + id).catch((e: AxiosError) => {
            return e.response?.data
        }),
})
