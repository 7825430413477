const cookies = {
    access_token_key: 'megad-access-token-key',
    token_expires_at_key: 'megad-token-expiration-key',
    hide_links_warning_key: 'megad-links-warning-key',
}

const banners = {
    upBanner: 'mega-top-banner-key',
    downBanner: 'mega-bottom-banner-key',
}

export { cookies, banners }
