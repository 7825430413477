import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { AxiosError } from 'axios'
import endpoints from '@/api/endpoints/info'

export default (AxiosInstance: NuxtAxiosInstance) => ({
    loadPrivacyPolicy: () =>
        AxiosInstance.$get(endpoints.privacyPolicy.path).catch((e: AxiosError) => e.response?.data),
    loadAboutUs: () => AxiosInstance.$get(endpoints.aboutUs.path).catch((e: AxiosError) => e.response?.data),
    loadContactUs: () =>
        AxiosInstance.$get(endpoints.contactUs.path).catch((e: AxiosError) => e.response?.data),
    loadCookies: () => AxiosInstance.$get(endpoints.cookies.path).catch((e: AxiosError) => e.response?.data),
    loadLegalWarning: () =>
        AxiosInstance.$get(endpoints.legalWarning.path).catch((e: AxiosError) => e.response?.data),
    loadPress: () => AxiosInstance.$get(endpoints.press.path).catch((e: AxiosError) => e.response?.data),
    loadJob: () => AxiosInstance.$get(endpoints.job.path).catch((e: AxiosError) => e.response?.data),
    loadFaq: () => AxiosInstance.$get(endpoints.faq.path).catch((e: AxiosError) => e.response?.data),
    loadHelp: () => AxiosInstance.$get(endpoints.help.path).catch((e: AxiosError) => e.response?.data),
    loadCommunityRules: () =>
        AxiosInstance.$get(endpoints.communityRules.path).catch((e: AxiosError) => e.response?.data),
    loadContact: () => AxiosInstance.$get(endpoints.contact.path).catch((e: AxiosError) => e.response?.data),
    postContact: (payload: object) =>
        AxiosInstance.$post(endpoints.contact.path, payload).catch((e: AxiosError) => e.response?.data),
})
