import { render, staticRenderFns } from "./User.vue?vue&type=template&id=e84bc9c8&scoped=true&"
import script from "./User.vue?vue&type=script&lang=ts&"
export * from "./User.vue?vue&type=script&lang=ts&"
import style0 from "./User.vue?vue&type=style&index=0&id=e84bc9c8&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e84bc9c8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MicroUserImage: require('/home/cleavr/www.jdescuentos.cl/releases/20240822032920153/components/micro/userImage.vue').default,DropdownUser: require('/home/cleavr/www.jdescuentos.cl/releases/20240822032920153/components/dropdown/User.vue').default,LayoutOverlay: require('/home/cleavr/www.jdescuentos.cl/releases/20240822032920153/components/layout/Overlay.vue').default})
