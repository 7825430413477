import Vue from 'vue'

import VueScrollTo from 'vue-scrollto'

import VueClipboard from 'vue-clipboard2'

import { ClickOutside } from '@/vue/click-outside'

import ScrollLocker from '@/vue/scroll-locker'

import BreakpointsDetector from '@/vue/breakpoints-detector'

import ScrollObserver from '@/vue/scroll-observer'

import DiscountOpener from '@/vue/discount-opener'

Vue.directive('click-outside', ClickOutside)

Vue.directive('scroll-lock', ScrollLocker)

Vue.use(BreakpointsDetector)

Vue.use(DiscountOpener)

Vue.use(ScrollObserver)

Vue.use(VueScrollTo, {
    container: 'body',
    duration: 600,
    easing: 'ease',
    offset: -85,
    force: true,
    cancelable: true,
    onStart: false,
    onDone: false,
    onCancel: false,
    x: false,
    y: true,
})

VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)
