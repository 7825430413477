
import Vue from 'vue'

export default Vue.extend({
    name: 'SidebarSocialNetworksSkeleton',
    data() {
        return {
            skeletons: [1, 2, 3, 4, 5, 6],
        }
    },
})
