import { AxiosError } from 'axios'
import { NuxtAxiosInstance } from '@nuxtjs/axios'
import endpoints from '@/api/endpoints/general'

export default (AxiosInstance: NuxtAxiosInstance) => ({
    useScrapper: (query: object) =>
        AxiosInstance.$get(endpoints.scrapper.path, { params: query }).catch(
            (e: AxiosError) => e.response?.data
        ),
    useCoupon: (slug: string) =>
        AxiosInstance.$get(endpoints.useCoupon.path.replace('_SLUG_', slug)).catch(
            (e: AxiosError) => e.response?.data
        ),
    search: (term: string) => {
        return AxiosInstance.$get(`${endpoints.search.path}?search=${term}`).catch(
            (e: AxiosError) => e.response?.data
        )
    },
    loadGeneral: () => AxiosInstance.$get(endpoints.general.path).catch((e: AxiosError) => e.response?.data),
    loadHeadband: () =>
        AxiosInstance.$get(endpoints.general.links_headband).catch((e: AxiosError) => e.response?.data),
    loadChildrenComments: (id: string | number) =>
        AxiosInstance.$get(endpoints.childrenComments.path + '/' + id).catch(
            (e: AxiosError) => e.response?.data
        ),
    loadCategories: (query?: object) =>
        AxiosInstance.$get(endpoints.categories.path, { params: query }).catch(
            (e: AxiosError) => e.response?.data
        ),
    loadForumCategories: () =>
        AxiosInstance.$get(endpoints.forumCategories.path).catch((e: AxiosError) => e.response?.data),
    loadCountries: () =>
        AxiosInstance.$get(endpoints.countries.path).catch((e: AxiosError) => e.response?.data),
    loadGiftsHistory: (userId: string | number) =>
        AxiosInstance.$get(endpoints.giftsHistory.path.replace('_ID_', String(userId))),
    loadGifts: (query: object) =>
        AxiosInstance.$get(endpoints.gifts.path, { params: query }).catch(
            (e: AxiosError) => e.response?.data
        ),
    redeemGifts: (body: { gift_id: string | number }) =>
        AxiosInstance.$post(endpoints.redeemGifts.path, body).catch((e: AxiosError) => e.response?.data),
    loadComments: (query: object) => AxiosInstance.$get(endpoints.comments.path, { params: query }),
    loadDiscounts: (query: object) =>
        AxiosInstance.$get(endpoints.discounts.path, { params: query }).catch(
            (e: AxiosError) => e.response?.data
        ),
    loadSavedDiscounts: (userId: string, query: object) =>
        AxiosInstance.$get(endpoints.savedDiscounts.path.replace('_ID_', userId), { params: query }),

    loadCoupons: (query: object) =>
        AxiosInstance.$get(endpoints.coupons.path, { params: query }).catch(
            (e: AxiosError) => e.response?.data
        ),
    loadOffers: (query: object) =>
        AxiosInstance.$get(endpoints.offers.path, { params: query }).catch(
            (e: AxiosError) => e.response?.data
        ),
    loadNovelties: (query: object) =>
        AxiosInstance.$get(endpoints.novelties.path, { params: query }).catch(
            (e: AxiosError) => e.response?.data
        ),
    loadLotteries: (query: object) =>
        AxiosInstance.$get(endpoints.lotteries.path, { params: query }).catch(
            (e: AxiosError) => e.response?.data
        ),
    loadStores: (query: object) =>
        AxiosInstance.$get(endpoints.stores.path, { params: query }).catch(
            (e: AxiosError) => e.response?.data
        ),
    ratingStore: (body: { store_id: number; grade: number }) =>
        AxiosInstance.$post(endpoints.stores.rating, body).catch((e: AxiosError) => e.response?.data),
    loadForums: (query: object) =>
        AxiosInstance.$get(endpoints.forums.path, { params: query }).catch(
            (e: AxiosError) => e.response?.data
        ),
    loadArticles: (query: object) =>
        AxiosInstance.$get(endpoints.articles.path, { params: query }).catch(
            (e: AxiosError) => e.response?.data
        ),
    loadNotifications: (query?: object) =>
        AxiosInstance.$get(endpoints.notifications.path, { params: query }).catch(
            (e: AxiosError) => e.response?.data
        ),
    notificationsCounter: () => {
        return AxiosInstance.$get(endpoints.notificationsCounter.path).catch(
            (e: AxiosError) => e.response?.data
        )
    },
    messagesCounter: () => {
        return AxiosInstance.$get(endpoints.messagesCounter.path).catch((e: AxiosError) => e.response?.data)
    },
    loadUsers: (query: object) =>
        AxiosInstance.$get(endpoints.users.path, { params: query }).catch(
            (e: AxiosError) => e.response?.data
        ),
    loadLastActivities: (id: number, query: object) =>
        AxiosInstance.$get(endpoints.lastActivities.path.replace('_ID_', String(id)), {
            params: query,
        }).catch((e: AxiosError) => {
            return e.response?.data
        }),
    loadCoinHistory: (id: number, query: object) =>
        AxiosInstance.$get(endpoints.coinHistory.path.replace('_ID_', String(id)), {
            params: query,
        }).catch((e: AxiosError) => {
            return e.response?.data
        }),
    loadOfferShortLink: (offerId: string | number) =>
        AxiosInstance.$get(endpoints.shortLink.offer.replace('_ID_', String(offerId))),
    loadCouponShortLink: (couponId: string | number) =>
        AxiosInstance.$get(endpoints.shortLink.coupon.replace('_ID_', String(couponId))),
    loadLotteryShortLink: (lotteryHash: string) =>
        AxiosInstance.$get(endpoints.shortLink.lottery.replace('_HASH_', lotteryHash)),
    loadStoreShortLink: (storeId: string | number) =>
        AxiosInstance.$get(endpoints.shortLink.store.replace('_ID_', String(storeId))),
    loadRedirects: (path: string) =>
        AxiosInstance.$get(endpoints.redirects.path.replace('_FROM_', path)).catch((e: AxiosError) => {
            return e.response?.data
        }),
    sendExtensionUninstallFeedback: (body: object) =>
        AxiosInstance.$post(endpoints.extension.path, body).catch((e: AxiosError) => {
            return e.response?.data
        }),
    newsletterSubscription: (body: object) =>
        AxiosInstance.$post(endpoints.newsletter.subscribe, body).catch((e: AxiosError) => {
            return e.response?.data
        }),
    newsletterVerify: (body: object) =>
        AxiosInstance.$post(endpoints.newsletter.verify, body).catch((e: AxiosError) => {
            return e.response?.data
        }),
    newsletterUnsubscription: (body: object) =>
        AxiosInstance.$post(endpoints.newsletter.unsuscribe, body).catch((e: AxiosError) => {
            return e.response?.data
        }),
})
