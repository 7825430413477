import { Plugin } from '@nuxt/types'

const png = '/assets/png/'
const svg = '/assets/svg/'
const jpg = '/assets/jpg/'

const primaryColorSVGsPath = svg + (process.env.VARIANT + '/primary/')
const secondaryColorSVGsPath = svg + (process.env.VARIANT + '/secondary/')

/* const variantPngPath = png + process.env.VARIANT + '/' */
const variantJpgPath = jpg + process.env.VARIANT + '/'

const assets = {
    demos: {
        amazon: svg + 'amazon.svg',
        userPhoto: png + 'userPhoto.png',

        // eliminar a continuación:
        uberEats: svg + 'uber-eats.svg',
        cssawesome: png + 'css-awesome.png',
        couponImage: png + 'coupon-demo-image.png',
        subway: svg + 'subway.svg',
        pizzaHut: svg + 'pizza-hut.svg',
        kfc: svg + 'kfc.svg',
        burgerKing: svg + 'burger-king.svg',
    },
    auth: {
        login: {
            city: svg + 'city-auth-illustration-login.svg',
            fem: svg + 'fem-auth-illustration-login.svg',
            masc: svg + 'masc-auth-illustration-login.svg',
            people: svg + 'people-auth-illustration-login.svg',
            modalImage: svg + 'modal-login-image.svg',
            icons: {
                chat: png + 'item-login-chat.png',
                gift: png + 'item-login-gift.png',
                people: png + 'item-login-people.png',
            },
        },
        register: {
            city: svg + 'city-auth-illustration-register.svg',
            fem: svg + 'fem-auth-illustration-register.svg',
            masc: svg + 'masc-auth-illustration-register.svg',
            people: svg + 'people-auth-illustration-register.svg',
            submit: svg + 'modal-email-submit.svg',
            modalImage: svg + 'modal-register-image.svg',
        },
        recovery: {
            city: svg + 'city-auth-illustration-recovery.svg',
            one: svg + 'one-auth-illustration-recovery.svg',
            two: svg + 'two-auth-illustration-recovery.svg',
            modalImage: svg + 'modal-reset-image.svg',
            modalBg: png + 'modal-reset-bg.png',
            emailWay: png + 'modal-digits-image.png',
            passwordWay: png + 'modal-new-pass-image.png',
            success: png + 'modal-reset-success-image.png',
            success2: png + 'modal-reset-success-image-2.png',
        },
    },
    share: {
        preview: '/preview.jpg',
        stepper: {
            first: svg + 'share-stepper-first.svg',
            second: svg + 'share-stepper-second.svg',
            third: svg + 'share-stepper-third.svg',
            firstInactive: svg + 'share-stepper-first-inactive.svg',
            secondInactive: svg + 'share-stepper-second-inactive.svg',
            thirdInactive: svg + 'share-stepper-third-inactive.svg',
        },
        lastStep: {
            cuponAndOffer: svg + 'cupon-and-offer-share-step-3-illustration.svg',
            survey: svg + 'survey-share-step-2-illustration.svg',
            chat: svg + 'chat-share-step-2-illustration.svg',
        },
    },
    surveys: {
        banner: png + 'banner-surveys.png',
        one: png + 'illustration-one-surveys.png',
        two: png + 'illustration-two-surveys.png',
        default: svg + 'surveys-default-illustration.svg',
    },
    search: {
        head: svg + 'head-search.svg',
    },
    error: {
        number: svg + '404.svg',
        cone: svg + 'cone.svg',
        dots: svg + 'dots-error.svg',
        personError: svg + 'person-error-illustration.svg',
        wallpaper: png + 'error-wallpaper.png',
    },
    misc: {
        menuArrow: svg + 'menu-arrow.svg',
        ribbonRegister: svg + 'ribbon.svg',
        contactIcon: svg + 'contact-icon.svg',
        buttonIcon: svg + 'button-icon.svg',
        buttonBend: svg + 'icon-bend-button.svg',
        elipse: png + 'elipse.png',
        buttonArrow: png + 'right-arrow.png',
        buttonDownload: svg + 'download-icon.svg',
        buttonDownloadPurple: svg + 'download-blue-icon.svg',
        face1Emoji: svg + 'face-1-emoji.svg',
        face2Emoji: svg + 'face-2-emoji.svg',
        clockEmoji: svg + 'clock-emoji.svg',
        face3Emoji: svg + 'face-3-emoji.svg',
        dancingWomanEmoji: svg + 'dancing-woman-emoji.svg',
    },
    brand: {
        footerLogo: '/assets/png/' + process.env.VARIANT + '/footer-logo.png',
        headerLogo: '/assets/png/' + process.env.VARIANT + '/logo-light.png',
        headerLogoLetter: '/assets/png/' + process.env.VARIANT + '/logo-letter.png',
        loaderLogoLetter: '/assets/png/' + process.env.VARIANT + '/logo-letter.png',
        favicon: svg + 'md-favicon.svg',
        ctaLogo: '/favicon/favicon.ico',
    },
    screenshots: {
        extensionLandingXXL: '/assets/png/' + process.env.VARIANT + '/extension-landing-background-xxl.png',
        extensionLandingXL: '/assets/png/' + process.env.VARIANT + '/extension-landing-background-xl.png',
        extensionLandingLG: '/assets/png/' + process.env.VARIANT + '/extension-landing-background-lg.png',
        extensionLandingMD: '/assets/png/' + process.env.VARIANT + '/extension-landing-background-md.png',
        extensionLandingSM: '/assets/png/' + process.env.VARIANT + '/extension-landing-background-sm.png',
        extensionLandingMB: '/assets/png/' + process.env.VARIANT + '/extension-landing-background-mb.png',
    },
    blogs: {
        phone: svg + 'phone-blogs.svg',
        woman: svg + 'woman-blogs.svg',
        shop: svg + 'shopping-blogs.svg',
        shelter: svg + 'shelter-blogs.svg',
        megaphone: svg + 'megaphone-blogs.svg',
    },
    illustration: {
        dashSummary: svg + 'dashboard-summary.svg',
        dashOffer: svg + 'dashboard-offer.svg',
        dashGift: svg + 'dashboard-gift.svg',
        dashChats: svg + 'dashboard-chats.svg',
        bannerSidebar1: variantJpgPath + 'banner-sidebar-1.jpg',
        bannerSidebar2: variantJpgPath + 'banner-sidebar-2.jpg',
        bannerSidebar3: variantJpgPath + 'banner-sidebar-3.jpg',
        bannerSidebar4: variantJpgPath + 'banner-sidebar-4.jpg',
        bannerSidebar5: variantJpgPath + 'banner-sidebar-5.jpg',
        bannerSidebar6: variantJpgPath + 'banner-sidebar-6.jpg',
        homepageHeader: png + 'homepage-header-illustration.png',
        uninstall: svg + 'uninstall-illustration.svg',
        waveOpen: svg + 'wave-open.svg',
        likeSprite: png + 'heart-sprite.png',
        newMessage: svg + 'new-message.svg',
        bannerAbout: svg + 'banner-about.svg',
        bannerExtension: svg + 'extension-banner.svg',
        bannerExtensionApp: svg + 'extension-download-banner.svg',
        extensionImage: svg + 'extension-image.svg',
        extensionImageFree: svg + 'extension-image-free.svg',
        extensionImageCupon: svg + 'extension-image-cupon.svg',
        extensionImageRegister: svg + 'extension-image-register.svg',
        extensionImageApp: svg + 'extension-image-app.svg',
        extensionImageShop: svg + 'extension-image-shop.svg',
        dashboardNoOffers: svg + 'dashboard-no-deals.svg',
        dashboardNoComments: svg + 'dashboard-no-comments.svg',
        dashboardHeader: svg + 'dashboard-header-background.svg',
        dashboardTab: svg + 'dashboard-tabs-background.svg',
        dashboardDiscountsSaved: svg + 'dashboard-discounts-saved.svg',
        dashboardSettings: svg + 'dashboard-settings.svg',
        dashboardGift: svg + 'dashboard-gift2.svg',
        login: png + 'login-illustration.png',
        register: png + 'register-illustration.png',
        unauthorized: svg + 'unauthorized-illustration.svg',
        searchNotFound: png + 'search-not-found-illustration.png',
        searchFailed: svg + 'search-failed-illustration.svg',
        welcomeIcons: svg + 'welcome-icons.svg',
        welcomeMex: svg + 'welcome-mex-illustration.svg',
        coupons: svg + 'coupons-illustration.svg',
        home: png + 'home-illustration.png',
        thumbsup: svg + 'thumbs-up-illustration.svg',
        firstGrade: svg + 'first-grade.svg',
        share: {
            offer: svg + process.env.VARIANT + '/offer-share-illustration.svg',
            coupon: svg + process.env.VARIANT + '/coupon-share-illustration.svg',
            survey: svg + process.env.VARIANT + '/survey-share-illustration.svg',
            chat: svg + process.env.VARIANT + '/chat-share-illustration.svg',
            newoffer: svg + 'offer-new-share-illustration.svg',
            newcoupon: svg + 'coupon-new-share-illustration.svg',
        },
        timeBanner: png + 'time-banner.png',
        shoppingBag: svg + 'shopping-bag.svg',
        megaphone: svg + 'megaphone.svg',
        ourVision: svg + 'our-vision.svg',
        ourTeam: svg + 'our-team.svg',
        aboutUs: {
            image1: svg + 'people-about-us.svg',
            image2: svg + 'people-about-us-2.svg',
            image3: svg + 'people-about-us-3.svg',
        },
        notificationsEmpty: svg + 'notifications-empty.svg',
        popularOffer: svg + 'popular-offer.svg',
        publishedSuccess: svg + 'published-success.svg',
        offerExpired: svg + 'offer-expired.svg',
        comment: svg + 'comment-illustration.svg',
        threadPublishSuccess: svg + 'thread-publish-success.svg',
        newBlogEntry: svg + 'new-blog-entry.svg',
        coinPurpleAndRed: svg + 'coin-purple-and-red.svg',
        coinBlueAndRed: svg + 'coin-blue-and-red.svg',
        coinLightBlueAndRed: svg + 'coin-light-blue-and-red.svg',
        coinRed: svg + 'coin-red.svg',
        coinGreenAndRed: svg + 'coin-green-and-red.svg',
        coinBlue: svg + 'coin-blue.svg',
        coinDefault: svg + 'coin-default.svg',
        news: svg + 'news-page.svg',
        onetemp: svg + 'onetemp-blue.svg',
        lessthanfivecomments: svg + 'less-than-five-comments.svg',
        morethanfivecomments: svg + 'more-than-five-comments.svg',
        commentRejected: svg + 'comment-rejected.svg',
        expiredOffer: svg + 'expired-offer.svg',
        banned: svg + 'banned-notification.svg',
        rejected: svg + 'rejected-notification.svg',
        acceptedGreen: svg + 'accepted-green.svg',
        cookies: svg + 'cookies-illustration.svg',
        cookiesAdjust: svg + 'cookies-adjust-illustration.svg',
        extensionUninstalledBgGreen: png + 'extension-uninstalled.png',
        extensionUninstalledOffer: svg + 'extension-uninstalled-offer.svg',
        eventImage: svg + 'event-image.svg',
        eventDefault: svg + 'event-default.svg',
        megaBrands: svg + 'mega-brands.svg',
        megaCategories: svg + 'mega-categories.svg',
        megaCommunity: svg + 'mega-comunidad.svg',
        megaCoupons: svg + 'mega-cupones-image.svg',
        megaLotteries: svg + 'mega-lotteries.svg',
        megaOfertas: svg + 'mega-ofertas.svg',
        noDiscountsFound: svg + 'no-discounts-found.svg',
        noDiscountsFoundTwo: svg + 'no-discounts-found-2.svg',
        coinsJoinFree: svg + process.env.VARIANT + '/coins-join-free.svg',
        coinMegaDescuentos: svg + 'coin-mega-descuentos.svg',
        coinPesosSymbol: svg + 'coin-pesos-symbol.svg',
        coinMegaDescuentos2: svg + 'coin-mega-descuentos-2.svg',
        coinPesosMDsymbol: svg + 'coin-md-pesos-symbol.svg',
        coinPesosMDsymbol2: svg + 'coin-md-pesos-symbol-2.svg',
        blog: {
            user1: svg + 'blog-user-1.svg',
            user2: svg + 'blog-user-2.svg',
            user3: svg + 'blog-user-3.svg',
        },
        banner: {
            coin: svg + 'coin-banner-illustration.svg',
            twitter: svg + 'twitter-banner-illustration.svg',
            instagram: svg + 'instagram-banner-illustration.svg',
            mega: svg + 'mega-banner-illustration.svg',
        },
        cta: {
            facebook: {
                logo: png + 'facebook-cta-image.png',
                tag: svg + 'facebook-cta-tag.svg',
            },
            whatsapp: {
                bg: svg + 'whatsapp-cta-bg.svg',
                logo: svg + 'whatsapp-cta-image.svg',
                tag: svg + 'whatsapp-cta-tag.svg',
            },
            extension: svg + 'cta-extension.svg',
            app: svg + 'cta-app.svg',
            fiveStars: svg + 'cta-five-stars.svg',
            googlechrome: svg + 'cta-google-chrome.svg',
            googleplay: svg + 'cta-google-play.svg',
            appleStore: svg + 'cta-app-store.svg',
            email: svg + 'cta-email.svg',
        },
    },
    black: {
        comment: svg + 'comment-black.svg',
        menuItems: svg + 'menu-items-black.svg',
        aboutTimer: svg + 'about-timer-black.svg',
        refreshed: svg + 'refreshed-black.svg',
        dropdown: svg + 'dropdown-comments-black.svg',
        local: svg + 'local-black.svg',
        arrowRight: svg + 'arrow-right-solid.svg',
        recentActivity: svg + 'recent-activity-black.svg',
    },
    aboutUs: {
        bannerAboutUs1: png + 'banner-about-us-1.png',
        bannerAboutUs2: png + 'banner-about-us-2.png',
        aboutPeople1: png + 'about-people-1.png',
        aboutPeople2: png + 'about-people-2.png',
    },
    orange: {
        fire: png + 'fire-orange.png',
        user: png + 'user-normal-orange.png',
        exclusive: svg + 'exclusive-orange.svg',
        gift: svg + 'gift-orange.svg',
    },
    blue: {
        loading: png + 'loading-blue.png',
        chat: svg + 'dashboard-chat-blue.svg',
        arrowLeft: svg + 'arrow-left-blue.svg',
        fire: png + 'fire-blue.png',
        offerFinished: png + 'offer-finished-blue.png',
        userAdmin: png + 'user-admin-blue.png',
        shop: svg + 'shop-blue.svg',
    },
    yellow: {
        hourglass: svg + 'hourglass-yellow.svg',
        coinStack: svg + 'dashboard-coin-stack-yellow.svg',
        coin: svg + 'dashboard-coin-yellow.svg',
        cup: svg + 'dashboard-cup-yellow.svg',
        offerStart: svg + 'offer-start-yellow.svg',
        coinPrize: svg + 'coin-prize-yellow.svg',
    },
    primary: {
        photoUpload: primaryColorSVGsPath + 'photo-upload.svg',
        dashChat: primaryColorSVGsPath + 'dashboard-chat.svg',
        offerPercent: primaryColorSVGsPath + 'offer-percent-blue.svg',
        sponsored: primaryColorSVGsPath + 'sponsored-tag-blue.svg',
        shield: primaryColorSVGsPath + 'shield-blue.svg',
        about: primaryColorSVGsPath + 'about-blue.svg',
        bookmark: primaryColorSVGsPath + 'bookmark-blue.svg',
        referralLink: primaryColorSVGsPath + 'referral-link-blue.svg',
        span: primaryColorSVGsPath + 'span-blue.svg',
        subscribe: primaryColorSVGsPath + 'subscribe-blue.svg',
        copyCode: primaryColorSVGsPath + 'copy-code-blue.svg',
        buttonBendCoupon: primaryColorSVGsPath + 'bend-primary.svg',
        submit: primaryColorSVGsPath + 'submit-blue.svg',
        trash: primaryColorSVGsPath + 'dashboard-trash-blue.svg',
        dashBell: primaryColorSVGsPath + 'dashboard-bell-blue.svg',
        dashCamera: primaryColorSVGsPath + 'dashboard-camera-blue.svg',
        dashShare: primaryColorSVGsPath + 'dashboard-share-blue.svg',
        checkbox: primaryColorSVGsPath + 'dashboard-checkbox-blue.svg',
        unknown: primaryColorSVGsPath + 'dashboard-unknown-blue.svg',
        gear: primaryColorSVGsPath + 'dashboard-gear-blue.svg',
        giftcard: primaryColorSVGsPath + 'dashboard-giftcard-blue.svg',
        dashStatistic: primaryColorSVGsPath + 'dashboard-statistic-blue.svg',
        dashComments: primaryColorSVGsPath + 'dashboard-comments-blue.svg',
        dashCoin: primaryColorSVGsPath + 'dashboard-coin-blue.svg',
        dashCoupon: primaryColorSVGsPath + 'dashboard-coupon-blue.svg',
        dashCup: primaryColorSVGsPath + 'dashboard-cup-blue.svg',
        dashLike: primaryColorSVGsPath + 'dashboard-like-blue.svg',
        dashOffersAndCoupons: primaryColorSVGsPath + 'dashboard-offers-and-coupons-blue.svg',
        dashLottery: primaryColorSVGsPath + 'dashboard-lottery-blue.svg',
        verified: primaryColorSVGsPath + 'verified-blue.svg',
        homeMenu: primaryColorSVGsPath + 'home-menu-blue.svg',
        search: primaryColorSVGsPath + 'search-blue.svg',
        coupons: primaryColorSVGsPath + 'dashboard-coupon-blue.svg',
        seeMore: primaryColorSVGsPath + 'see-more-blue.svg',
        pets: primaryColorSVGsPath + 'pets-blue.svg',
        safetyFinance: primaryColorSVGsPath + 'safety-and-finance-blue.svg',
        restaurantFood: primaryColorSVGsPath + 'restaurants-and-food-blue.svg',
        homeGarden: primaryColorSVGsPath + 'home-and-garden-blue.svg',
        sports: primaryColorSVGsPath + 'sports-blue.svg',
        carsBikes: primaryColorSVGsPath + 'cars-and-bikes-blue.svg',
        videogames: primaryColorSVGsPath + 'videogames-blue.svg',
        clothesAccesories: primaryColorSVGsPath + 'clothes-and-accesories-blue.svg',
        familyKids: primaryColorSVGsPath + 'family-kids-and-childrens-blue.svg',
        freetime: primaryColorSVGsPath + 'ocio-y-tiempo-libre-blue.svg',
        free: primaryColorSVGsPath + 'gratis-blue.svg',
        software: primaryColorSVGsPath + 'software-blue.svg',
        books: primaryColorSVGsPath + 'books-blue.svg',
        travels: primaryColorSVGsPath + 'viajes-blue.svg',
        diamond: primaryColorSVGsPath + 'diamond-blue.svg',
        arrowLeft: primaryColorSVGsPath + 'left-arrow-carrousel-blue.svg',
        arrowRight: primaryColorSVGsPath + 'right-arrow-carrousel-blue.svg',
        arrowDown: primaryColorSVGsPath + 'down-arrow-blue.svg',
        arrowRightCategory: primaryColorSVGsPath + 'arrow-right-blue.svg',
        arrowDownDouble: primaryColorSVGsPath + 'arrow-down-double-blue.svg',
        filterBy: primaryColorSVGsPath + 'filter-by-blue.svg',
        shopCheck: primaryColorSVGsPath + 'shop-check-blue.svg',
        ticket: primaryColorSVGsPath + 'ticket-blue.svg',
        shop: primaryColorSVGsPath + 'shop-blue.svg',
        similarCategories: primaryColorSVGsPath + 'similar-categories-blue.svg',
        question: primaryColorSVGsPath + 'question-blue.svg',
        questionMaletin: primaryColorSVGsPath + 'question-maletin-blue.svg',
        questionHowto: primaryColorSVGsPath + 'question-how-to-use-blue.svg',
        paymentMethods: primaryColorSVGsPath + 'payment-methods-blue.svg',
        socialsSidebar: primaryColorSVGsPath + 'socials-sidebar-blue.svg',
        plusRounded: primaryColorSVGsPath + 'plus-rounded-blue.svg',
        star: primaryColorSVGsPath + 'star-blue.svg',
        user: primaryColorSVGsPath + 'user-blue.svg',
        notificationsHeader: primaryColorSVGsPath + 'notifications-header-blue.svg',
        messagesHeader: primaryColorSVGsPath + 'messages-header-blue.svg',
        offer: primaryColorSVGsPath + 'offer-blue.svg',
        coins: primaryColorSVGsPath + 'coins-blue.svg',
        settingsUser: primaryColorSVGsPath + 'settings-blue.svg',
        logout: primaryColorSVGsPath + 'logout-blue.svg',
        share: primaryColorSVGsPath + 'share-blue.svg',
        brandsLike: primaryColorSVGsPath + 'brands-like-blue.svg',
        chat: primaryColorSVGsPath + 'chat-blue.svg',
        information: primaryColorSVGsPath + 'information-blue.svg',
        timeleft: primaryColorSVGsPath + 'timeleft-blue.svg',
        comments: primaryColorSVGsPath + 'comments-blue.svg',
        report: primaryColorSVGsPath + 'report-blue.svg',
        link: primaryColorSVGsPath + 'link-blue.svg',
        crown: primaryColorSVGsPath + 'crown-blue.svg',
        bug: primaryColorSVGsPath + 'bug-blue.svg',
        duplicated: primaryColorSVGsPath + 'duplicated-blue.svg',
        expired: primaryColorSVGsPath + 'expired-offer-blue.svg',
        heart: primaryColorSVGsPath + 'heart-blue.svg',
        ship: primaryColorSVGsPath + 'ship-blue.svg',
        percent: primaryColorSVGsPath + 'percent-blue.svg',
        cash: primaryColorSVGsPath + 'cash-blue.svg',
        calendar: primaryColorSVGsPath + 'calendar-blue.svg',
        valorate: primaryColorSVGsPath + 'valorate-blue.svg',
        valorateFilled: primaryColorSVGsPath + 'valorate-filled-blue.svg',
        download: primaryColorSVGsPath + 'download-blue.svg',
        shipment: primaryColorSVGsPath + 'shipments-blue.svg',
        fullArrowRight: primaryColorSVGsPath + 'full-arrow-right-blue.svg',
        chevronArrowRight: primaryColorSVGsPath + 'circle-chevron-right-blue.svg',
        userAccount: primaryColorSVGsPath + 'account-blue.svg',
        userNotifications: primaryColorSVGsPath + 'user-notifications-blue.svg',
        userMessages: primaryColorSVGsPath + 'user-messages-blue.svg',
        userSaved: primaryColorSVGsPath + 'user-saved-blue.svg',
        userCoins: primaryColorSVGsPath + 'user-coins-blue.svg',
        userThrophies: primaryColorSVGsPath + 'user-trophies-blue.svg',
        userSettings: primaryColorSVGsPath + 'user-settings-blue.svg',
        mail: primaryColorSVGsPath + 'mail-blue.svg',
        bag: primaryColorSVGsPath + 'icon-bag.svg',
        close: primaryColorSVGsPath + 'close-blue.svg',
        loading: png + (process.env.VARIANT === 'megadescuentos' ? 'loading-blue.png' : 'loading-blue.png'),
    },
    secondary: {
        bookmark: secondaryColorSVGsPath + 'bookmark-yellow.svg',
        hourglass: secondaryColorSVGsPath + 'hourglass-yellow.svg',
        copyCode: secondaryColorSVGsPath + 'copy-code-yellow.svg',
        broadcast: secondaryColorSVGsPath + 'dashboard-broadcast-yellow.svg',
        promotionsSvg: secondaryColorSVGsPath + 'promotions-yellow.svg',
        seeOffer: secondaryColorSVGsPath + 'see-offer-yellow.svg',
        offers: secondaryColorSVGsPath + 'offers-yellow.svg',
        comments: secondaryColorSVGsPath + 'comments-yellow.svg',
        clock: secondaryColorSVGsPath + 'clock-yellow.svg',
        magic: secondaryColorSVGsPath + 'magic-yellow.svg',
    },
    green: {
        connected: svg + 'dashboard-connected-green.svg',
        verified: svg + 'verified-green.svg',
        notification: svg + 'notification-green.svg',
    },
    white: {
        successThing: svg + 'success-white.svg',
        loading: png + 'loading-white.png',
        edit: svg + 'messenger-edit-white.svg',
        arrowRight: svg + 'arrow-rigth-white.svg',
        help: svg + 'help-icon-white.svg',
        finalizeIn: svg + 'finalize-in-white.svg',
        lineThroughBell: svg + 'dashboard-line-through-bell-white.svg',
        bell: svg + 'dashboard-bell-white.svg',
        clock: svg + 'dashboard-clock-white.svg',
        brandsMenu: png + 'brands-menu-white.png',
        categoriesMenu: png + 'categories-menu-white.png',
        categoryMenu: png + 'category-menu-white.png',
        chatMenu: png + 'chat-menu-white.png',
        couponsMenu: png + 'coupons-menu-white.png',
        freeMenu: png + 'free-menu-white.png',
        homeMenu: png + 'home-menu-white.png',
        lotteryMenu: png + 'lottery-menu-white.png',
        pencilMenu: png + 'pencil-menu-white.png',
        newsMenu: png + 'news-menu-white.png',
        offersMenu: png + 'offers-menu-white.png',
        trash: png + 'trash-white.png',
        error: png + 'error-white.png',
        errorform: svg + 'icon-error.svg',
        success: png + 'success-white.png',
        warning: png + 'warning-white.png',
        arrowTop: png + 'arrow-top-white.png',
        search: svg + 'search-white.svg',
        close: png + 'close-white.png',
        userHead: svg + 'user-header-white.svg',
        user: png + 'footer1-c-white.png',
        share: png + 'share-white.png',
        seeOffer: svg + 'see-offer-white.svg',
        coupons: svg + 'dashboard-coupon-white.svg',
        shop: svg + 'shop-white.svg',
        offers: svg + 'offers-white.svg',
        menuItems: svg + 'menu-items-white.svg',
        gift: svg + 'surveys-white.svg',
        plus: svg + 'plus-white.svg',
        plusBlack: svg + 'plus-black.svg',
        timeleft: svg + 'timeleft-white.svg',
        ship: svg + 'ship-white.svg',
        logout: svg + 'logout-white.svg',

        createThread: svg + 'create-thread-white.svg',

        answerComment: svg + 'answer-comment-white.svg',
        like: svg + 'like-white.svg',
        equis: svg + 'x-white.svg',
        voteup: svg + 'voteup-white.svg',
        // success: svg + 'success-white.svg',
        successClean: svg + 'success-clean-white.svg',
        expired: svg + 'expired-white.svg',
        popular: svg + 'popular-white.svg',
        chat: svg + 'chat-white.svg',
        minus: svg + 'minus-white.svg',

        bestOffers: svg + 'best-offers-white.svg',
    },
    gray: {
        info: svg + 'info-gray.svg',
        closeModal: svg + 'modal-close-icon-gray.svg',
        backModal: svg + 'modal-back-icon-gray.svg',
        bell: svg + 'bell-gray.svg',
        noComments: svg + 'no-comments-found.svg',
        noRewards: svg + 'no-rewards-found.svg',
        noRewardsWon: svg + 'no-rewards-won-found.svg',
        noSavedOffers: svg + 'no-saved-offers-found.svg',
        noActivities: svg + 'no-activities-found.svg',
        noChats: svg + 'no-chats-found.svg',
        noDiscounts: svg + 'no-discounts-found.svg',
        noLotteries: svg + 'no-lotteries-found.svg',
        like: svg + 'like-gray.svg',
        dislike: svg + 'dislike-gray.svg',
        clock: svg + 'clock-gray.svg',
        buttonDots: svg + 'button-dots-gray.svg',
        loading: png + 'loading-gray.png',
        gear: svg + 'gear-gray.svg',
        check: svg + 'check-gray.svg',
        closeRounded: png + 'close-rounded-gray.png',
        close: png + 'close-gray.png',
        messages: svg + 'messages-gray.svg',
        notifications: svg + 'notifications-gray.svg',
        arrowLeft: svg + 'arrow-left-gray.svg',
        arrowRight: svg + 'arrow-right-gray.svg',
        arrowDown: svg + 'arrow-down-gray.svg',
        comment: svg + 'comment-gray.svg',
        fire: png + 'fire-gray.png',
        footer: png + 'footer-gray.png',
        goToOffer: png + 'go-to-offer-gray.png',
        link: png + 'link-gray.png',
        local: png + 'local-gray.png',
        save: png + 'save-gray.png',
        shipment: svg + 'shipments-gray.svg',
        user: png + 'user-gray.png',
        categories: svg + 'categories-gray.svg',
        offerFinishIn: svg + 'offer-finalizes-in-gray.svg',
        refreshedAt: svg + 'refreshed-at.svg',
        mainCardMenu: svg + 'main-card-menu-gray.svg',

        userHeader: svg + 'user-gray.svg',
        notificationsHeader: svg + 'notifications-header-gray.svg',
        messagesHeader: svg + 'messages-header-gray.svg',
        offer: svg + 'offer-gray.svg',
        bookmark: svg + 'bookmark-gray.svg',
        trophy: svg + 'trophy-gray.svg',
        settings: svg + 'settings-gray.svg',
        logout: svg + 'logout-gray.svg',

        eye: svg + 'eye-gray.svg',
        popular: svg + 'popular-gray.svg',
        plusRounded: png + 'plus-rounded-gray.png',

        dropdownMenuDots: svg + 'dropdown-comments-gray.svg',
        rightArrowSmall: svg + 'right-arrow-gray.svg',
        linkSoft: svg + 'link-soft-gray.svg',
        cuponSoft: svg + 'cupon-soft-gray.svg',

        heart: svg + 'heart-gray.svg',
        world: svg + 'world-gray.svg',

        replies: svg + 'answers-gray.svg',
        plane: svg + 'plane-gray.svg',

        lastRefresh: svg + 'last-refresh-gray.svg',
        createdAt: svg + 'created-at-gray.svg',
        readtime: svg + 'readtime-gray.svg',

        tools: svg + 'notifications-page-gray.svg',
        remove: svg + 'remove-gray.svg',
        deactivate: svg + 'deactivate-gray.svg',
        exit: svg + 'exit-gray.svg',
        arrowGrayRight: svg + 'arrow-right.svg',
        mute: svg + 'mute-gray.svg',

        // user menu
        userAccount: svg + 'account-gray.svg',
        userNotifications: svg + 'user-notifications-gray.svg',
        userMessages: svg + 'user-messages-gray.svg',
        userSaved: svg + 'user-saved-gray.svg',
        userCoins: svg + 'user-coins-gray.svg',
        userThrophies: svg + 'user-trophies-gray.svg',
        userSettings: svg + 'user-settings-gray.svg',
        userLogout: svg + 'user-logout-gray.svg',

        noPrizes: svg + 'no-prizes-gray.svg',
        noPrizesAvailable: svg + 'no-prizes-available-gray.svg',
    },
    red: {
        hourglass: svg + 'hourglass-red.svg',
        clock: svg + 'clock-red.svg',
        loading: png + 'loading-red.png',
        disconnected: svg + 'dashboard-disconnected-red.svg',
        formError: svg + 'form-error-red.svg',
        arrowRight: svg + 'arrow-right-red.svg',
        fire: png + 'fire-red.png',
        timeleft: png + 'timeleft-red.png',
        timeleftsvg: svg + 'timeleft-red.svg',
        timer: svg + 'clock.svg',
    },
    social: {
        facebook: svg + 'facebook.svg',
        telegram: svg + 'telegram.svg',
        twitter: svg + 'twitter.svg',
        whatsapp: svg + 'whatsapp.svg',
        instagramWhite: svg + 'instagram-white.svg',
        facebookNotCircular: svg + 'facebook-not-circular.svg',
        telegramNotCircular: svg + 'telegram-not-circular.svg',
        twitterNotCircular: svg + 'twitter-not-circular.svg',
        twitterNotCircularWhite: svg + 'twitter-not-circular-white.svg',
        whatsappNotCircular: svg + 'whatsapp-not-circular.svg',
        messenger: svg + 'messenger.svg',
        instagram: svg + 'instagram.svg',
        apple: svg + 'apple.svg',
        googleAccount: svg + 'google-account.svg',
        facebookAccount: svg + 'facebook-account.svg',
        googlePlay: svg + 'google-play.svg',
        tiktok: svg + 'tiktok.svg',
        youtube: svg + 'youtube.svg',
        other: svg + 'other-social.svg',
        website: svg + 'website-social.svg',
        telegramFullImage: png + 'telegram-full-image.png',
        telegramTag: svg + 'telegram-tag.svg',
    },
}
declare module 'vue/types/vue' {
    interface Vue {
        $assets: typeof assets
    }
}

declare module '@nuxt/types' {
    interface Context {
        $assets: typeof assets
    }
}
const plugin: Plugin = (...params) => {
    params[1]('assets', assets)
}
export default plugin
