
import Vue from 'vue'
import { Models } from '~/types/models'

export default Vue.extend({
    name: 'LayoutHeader',
    data() {
        return {
            mounted: false,
            showUserPanel: true,
            showHeader: true,
            preventScrollListener: false,
            listener: (() => null) as (e?: any) => any,
        }
    },
    head() {
        return {
            link: [
                {
                    as: 'image',
                    type: 'image/png',
                    rel: 'preload',
                    href: this.$assets.brand.headerLogo,
                },
                {
                    rel: 'preload',
                    as: 'image',
                    type: 'image/png',
                    href: this.$assets.brand.headerLogoLetter,
                },
            ],
        }
    },
    computed: {
        top_links(): Models.Link[] | null {
            return this.$store.state.layout?.header?.links_headband || null
        },
        adbar(): any {
            return this.$store.state.layout?.header.adbar || false
        },
    },
    watch: {
        $route: {
            deep: true,
            handler() {
                this.showHeader = true
            },
        },
    },
    mounted() {
        let lastScrollY = window.scrollY

        setTimeout(() => {
            this.mounted = true
        }, 3000)

        this.listener = () => {
            if (!this.preventScrollListener) {
                this.showHeader =
                    lastScrollY > scrollY ||
                    (this.$refs.headerElm as HTMLElement).clientHeight * 1.5 > scrollY

                lastScrollY = window.scrollY
            }
        }

        document.addEventListener('scroll', this.listener)
    },
    destroyed() {
        document.removeEventListener('scroll', this.listener)
    },
    methods: {
        preventScroll() {
            this.preventScrollListener = true

            setTimeout(() => (this.preventScrollListener = false), 1000)
        },
        scrollTop() {
            document.documentElement.style.scrollBehavior = 'smooth'

            window.scroll(0, 1)

            document.documentElement.style.scrollBehavior = 'unset'
        },
        toggleUserPanel(show: boolean) {
            this.showUserPanel = show
        },
    },
})
