import { VueConstructor } from 'vue/types'

// import { endpoints } from '@/api/index'

declare module 'vue/types/vue' {
    interface Vue {
        $openCoupon: (slug: string | null, storeSlug?: string) => void
        $openOffer: (id: number | null) => void
        $openLottery: (hash: string | null) => void
    }
}

export default {
    install: (Vue: VueConstructor) => {
        Vue.prototype.$openCoupon = (slug: string, storeSlug?: string) => {
            if (storeSlug) {
                window.open(`${location.origin}/cupones-descuento/${storeSlug}?slug=${slug}`, '_blank')
                location.href = `${location.origin}/open/${slug}`
            } else {
                window.open(`${location.origin}/open/${slug}`, '_blank')
            }
        }
        Vue.prototype.$openOffer = (id: number) => {
            window.open(`${location.origin}/go/${id}`, '_blank')
        }
        Vue.prototype.$openLottery = (hash: string) => {
            window.open(`${location.origin}/goprize/${hash}`, '_blank')
        }
    },
}
