import { Context } from '@nuxt/types'

export default function (ctx: Context) {
    if (ctx.store.state.user.profile && ctx.store.state.user.profile.accepted_conditions_at) return

    ctx.redirect(302, { path: `/${ctx.$util.lang.routes.share}` })

    ctx.store.commit('setModal', {
        name: 'ModalAuthAcceptTerms',
        info: {
            message: ctx.$util.lang.middleware.ifAcceptTerms.text,
        },
    })
}
