import { Context } from '@nuxt/types'
import { MutationTree, GetterTree, ActionTree, ActionContext } from 'vuex/types'
import { Api } from '~/global'

export const state = () => ({
    profile: null as null | Api.Responses.User.SuccessProfile['data']['user'],
    userPreferences: null as null | object,
    notificationsCount: null as null | number,
    messagesCount: null as null | number,
})

export type RootState = ReturnType<typeof state>

export const mutations: MutationTree<RootState> = {
    setProfile: (state, profile: RootState['profile']) => {
        state.profile = profile
    },
    setUserPreferences: (state, userPreferences: RootState['userPreferences']) => {
        state.userPreferences = userPreferences
    },
    setNotificationsCount: (state, notificationsCount: RootState['notificationsCount']) => {
        state.notificationsCount = notificationsCount
    },
    setMessagesCount: (state, messagesCount: RootState['messagesCount']) => {
        state.messagesCount = messagesCount
    },
}
export const getters: GetterTree<RootState, RootState> = {
    getProfile({ profile }) {
        return profile
    },
    getUserPreferences({ userPreferences }) {
        return userPreferences
    },
}

export const actions: ActionTree<RootState, RootState> = {
    getNotificationsCount: async (
        ctx: ActionContext<RootState, RootState>,
        { $api }: { store: Context['store']; $api: Context['$api'] }
    ) => {
        try {
            const res = await $api.general.notificationsCounter()
            ctx.commit('setNotificationsCount', res.data.notification_count)
        } catch (error) {
            ctx.commit('setNotificationsCount', 0)
        }
    },
    getMessagesCount: async (
        ctx: ActionContext<RootState, RootState>,
        { $api }: { store: Context['store']; $api: Context['$api'] }
    ) => {
        try {
            const res = await $api.general.messagesCounter()
            ctx.commit('setMessagesCount', res.data.message_count)
        } catch (error) {
            ctx.commit('setMessagesCount', 0)
        }
    },
    showNotification: (...params) => {
        window.$nuxt.$root.$emit('notification', {
            text: params[1]?.text,
            duration: 3000,
            dismissButtonText: 'Ocultar',
            type: params[1]?.type,
        })
    },
}
