import { Plugin } from '@nuxt/types'

import LANG from '../lang/es'

// Endpoints protected by privacy policies and site terms
const protect: { [key: string]: string } = {
    '/v1/votes': LANG.plugins.axiosInterceptors.do_votes,
    '/v1/saves': LANG.plugins.axiosInterceptors.do_save_discounts,
    '/v1/expireds': LANG.plugins.axiosInterceptors.do_mark_expired,
    '/v1/reports': LANG.plugins.axiosInterceptors.do_report,
    '/v1/likes': LANG.plugins.axiosInterceptors.do_likes,
    '/v1/comments': LANG.plugins.axiosInterceptors.do_comment,
    '/v1/forums': LANG.plugins.axiosInterceptors.do_create_forum,
    '/v1/lotteries': LANG.plugins.axiosInterceptors.do_create_lottery,
    '/v1/coupons': LANG.plugins.axiosInterceptors.do_create_coupon,
    '/v1/offers': LANG.plugins.axiosInterceptors.do_create_offer,
    '/v1/stores/score': 'valorar una tienda',
}

const axiosInterceptorsInit: Plugin = ({ app, $axios, store, error, route }) => {
    $axios.onRequest((config) => {
        if (process.server) {
            /* console.log('Requesting data from: ' + config.url) */

            config.headers.origin = process.env.HOST
        }

        config.cancelToken = new $axios.CancelToken((cancel) => {
            if (!config.url || config.method !== 'post' || !protect[config.url]) return

            if (!store.getters['auth/isAuth']) {
                store.commit('setModal', { name: 'ModalAuthLogin' })
                cancel()
            }

            if (store.state.user.profile?.accepted_conditions_at === null) {
                const message = `${LANG.plugins.axiosInterceptors.for} ${protect[config.url]} ${
                    LANG.plugins.axiosInterceptors.need_accept_terms
                }`
                store.commit('setModal', { name: 'ModalAuthAcceptTerms', info: { message } })
                cancel(message)
            }

            if (store.state.user.profile && !store.state.user.profile.verified_email) {
                const message = `${LANG.plugins.axiosInterceptors.for} ${protect[config.url]} ${
                    LANG.plugins.axiosInterceptors.need_verify_account
                }`
                store.commit('setModal', {
                    name: 'ModalAuthEmailVerification',
                    info: { message, isForUserVerification: true },
                })
                cancel(message)
            }
        })

        if (store.state.auth.authData?.access_token && !config.headers.Authorization) {
            $axios.setToken(store.state.auth.authData?.access_token, store.state.auth.authData?.token_type)
        }
        if (!store.state.auth.authData?.access_token && config.headers.Authorization) {
            $axios.setToken(false)
        }
    })
    $axios.onResponse((response) => {
        /* if (process.server) {
            console.log('Receiving data from: ' + response.config.url)
        } */
        if (store.state.auth.authData?.access_token && process.browser && response.config.method === 'post') {
            store.dispatch('user/getNotificationsCount', app)
            store.dispatch('user/getMessagesCount', app)
        }
        if (
            response.config.url === '/v1/auth/logout' &&
            response.data.feedback === 'closed_session_success'
        ) {
            $axios.setToken(false)
        }
    })
    $axios.onResponseError((e) => {
        if (e.response?.data.feedback === 'not_allowed') {
            if (process.client) {
                store.dispatch('user/showNotification', {
                    text: LANG.plugins.axiosInterceptors.no_do_action,
                    type: 'error',
                })
            }
        }
        if (/(token_invalid|token_expired|token_not_found)/.test(e.response?.data.feedback)) {
            const isLocatedInDashboard = /dashboard-index/.test(route.name || '')

            $axios.setToken(false)

            store.commit('auth/setAuthenticationStatus', false)

            if (process.browser) {
                if (isLocatedInDashboard) {
                    error({
                        statusCode: 401,
                        message: LANG.plugins.axiosInterceptors.no_permission,
                    })
                } else {
                    store.commit('setModal', { name: 'ModalAuthLogin' })
                }
            }
        }
    })
}

export default axiosInterceptorsInit
