
import Vue from 'vue'

export default Vue.extend({
    name: 'MicroGlobalLoader',
    data() {
        return {
            loading: false,
        }
    },
    methods: {
        start() {
            this.loading = true
        },
        finish() {
            this.loading = false
        },
    },
})
