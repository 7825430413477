
import Vue from 'vue'

export default Vue.extend({
    name: 'DropdownUser',
    props: {
        options: {
            type: Array as () => Array<{
                title: string
                icon: { active: string; inactive: string }
                notifications?: number
                path?: string
                handler?: () => void
            }>,
            default: () => [],
        },
    },
    data() {
        return {}
    },
})
