
import { MetaInfo } from 'vue-meta/types'
import Vue, { PropType } from 'vue'

export default Vue.extend({
    name: 'Error',
    props: {
        error: {
            type: Object as PropType<{ statusCode: string | number; message: string }>,
        },
    },
    data() {
        return {
            backurl: null as null | History,
            backurlAvailable: false,
        }
    },
    head(): MetaInfo {
        const title = this.$util.lang.components.layoutError.title_seo
        const description = this.$util.lang.components.layoutError.description_seo
        return {
            link: [
                {
                    rel: 'canonical',
                    href: this.$util.host + this.$route.path,
                },
            ],
            ...this.$util.metaInit(
                [
                    ['description', description],
                    ['title', title],
                    ['og:url', this.$util.host + this.$route.path],
                    ['og:title', title],
                    ['og:image:alt', title],
                    ['og:description', description],
                ],
                title
            ),
        }
    },
    mounted() {
        this.backurl = window.history
        this.backurlAvailable = this.backurl && this.backurl.length > 1
    },
    methods: {
        goback(): void {
            if (this.backurl) {
                this.backurl.back()
            }
        },
    },
})
