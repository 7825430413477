import { MutationTree } from 'vuex/types'
import { Models } from '~/types/models'

export const state = () => ({
    categories: null as null | Models.Category[],
    forumCategories: null as null | Models.ForumCategory[],
    countries: null as null | Models.Country[],
})

export type RootState = ReturnType<typeof state>

export const mutations: MutationTree<RootState> = {
    setCategories: (state, categories: typeof state.categories) => (state.categories = categories),
    setForumCategories: (state, forumCategories: typeof state.forumCategories) =>
        (state.forumCategories = forumCategories),
    setCountries: (state, countries: typeof state.countries) => (state.countries = countries),
}
