
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
    name: 'LayoutCobee',
    data() {
        return {
            mounted: false,
        }
    },
    computed: {
        ...mapGetters({
            modal: 'getModal',
            modalSecondary: 'getModalSecondary',
            menu: 'getDropdown',
        }),
    },
    mounted() {
        this.$nextTick(() => {
            this.mounted = true
        })
    },
})
