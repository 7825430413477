export const DelayHydration = () => import('../../node_modules/nuxt-delay-hydration/dist/runtime/components/DelayHydration.vue' /* webpackChunkName: "components/delay-hydration" */).then(c => wrapFunctional(c.default || c))
export const HydrationStatus = () => import('../../node_modules/nuxt-delay-hydration/dist/runtime/components/HydrationStatus.vue' /* webpackChunkName: "components/hydration-status" */).then(c => wrapFunctional(c.default || c))
export const ExpiredSearchSection = () => import('../../components/ExpiredSearchSection.vue' /* webpackChunkName: "components/expired-search-section" */).then(c => wrapFunctional(c.default || c))
export const BannerGeneric = () => import('../../components/banner/Generic.vue' /* webpackChunkName: "components/banner-generic" */).then(c => wrapFunctional(c.default || c))
export const BannerRandom = () => import('../../components/banner/Random.vue' /* webpackChunkName: "components/banner-random" */).then(c => wrapFunctional(c.default || c))
export const BtnCoupon = () => import('../../components/btn/Coupon.vue' /* webpackChunkName: "components/btn-coupon" */).then(c => wrapFunctional(c.default || c))
export const BtnDiscount = () => import('../../components/btn/Discount.vue' /* webpackChunkName: "components/btn-discount" */).then(c => wrapFunctional(c.default || c))
export const BtnGeneric = () => import('../../components/btn/Generic.vue' /* webpackChunkName: "components/btn-generic" */).then(c => wrapFunctional(c.default || c))
export const BtnSeeMoreResults = () => import('../../components/btn/SeeMoreResults.vue' /* webpackChunkName: "components/btn-see-more-results" */).then(c => wrapFunctional(c.default || c))
export const CardAlertMessage = () => import('../../components/card/AlertMessage.vue' /* webpackChunkName: "components/card-alert-message" */).then(c => wrapFunctional(c.default || c))
export const CardBlog = () => import('../../components/card/Blog.vue' /* webpackChunkName: "components/card-blog" */).then(c => wrapFunctional(c.default || c))
export const CardComment = () => import('../../components/card/Comment.vue' /* webpackChunkName: "components/card-comment" */).then(c => wrapFunctional(c.default || c))
export const CardCommentThread = () => import('../../components/card/CommentThread.vue' /* webpackChunkName: "components/card-comment-thread" */).then(c => wrapFunctional(c.default || c))
export const CardEvent = () => import('../../components/card/Event.vue' /* webpackChunkName: "components/card-event" */).then(c => wrapFunctional(c.default || c))
export const CardExtension = () => import('../../components/card/Extension.vue' /* webpackChunkName: "components/card-extension" */).then(c => wrapFunctional(c.default || c))
export const CardFaq = () => import('../../components/card/Faq.vue' /* webpackChunkName: "components/card-faq" */).then(c => wrapFunctional(c.default || c))
export const CardFeatured = () => import('../../components/card/Featured.vue' /* webpackChunkName: "components/card-featured" */).then(c => wrapFunctional(c.default || c))
export const CardFeaturedGrid = () => import('../../components/card/FeaturedGrid.vue' /* webpackChunkName: "components/card-featured-grid" */).then(c => wrapFunctional(c.default || c))
export const CardFeaturedShop = () => import('../../components/card/FeaturedShop.vue' /* webpackChunkName: "components/card-featured-shop" */).then(c => wrapFunctional(c.default || c))
export const CardFeaturedStore = () => import('../../components/card/FeaturedStore.vue' /* webpackChunkName: "components/card-featured-store" */).then(c => wrapFunctional(c.default || c))
export const CardImagesGallery = () => import('../../components/card/ImagesGallery.vue' /* webpackChunkName: "components/card-images-gallery" */).then(c => wrapFunctional(c.default || c))
export const CardNews = () => import('../../components/card/News.vue' /* webpackChunkName: "components/card-news" */).then(c => wrapFunctional(c.default || c))
export const CardNotifications = () => import('../../components/card/Notifications.vue' /* webpackChunkName: "components/card-notifications" */).then(c => wrapFunctional(c.default || c))
export const CardOtherCountries = () => import('../../components/card/OtherCountries.vue' /* webpackChunkName: "components/card-other-countries" */).then(c => wrapFunctional(c.default || c))
export const CardPostUserInfo = () => import('../../components/card/PostUserInfo.vue' /* webpackChunkName: "components/card-post-user-info" */).then(c => wrapFunctional(c.default || c))
export const CardStoreArticle = () => import('../../components/card/StoreArticle.vue' /* webpackChunkName: "components/card-store-article" */).then(c => wrapFunctional(c.default || c))
export const ChipCategory = () => import('../../components/chip/Category.vue' /* webpackChunkName: "components/chip-category" */).then(c => wrapFunctional(c.default || c))
export const ChipGallery = () => import('../../components/chip/Gallery.vue' /* webpackChunkName: "components/chip-gallery" */).then(c => wrapFunctional(c.default || c))
export const ChipGeneric = () => import('../../components/chip/Generic.vue' /* webpackChunkName: "components/chip-generic" */).then(c => wrapFunctional(c.default || c))
export const CoverEvent = () => import('../../components/cover/Event.vue' /* webpackChunkName: "components/cover-event" */).then(c => wrapFunctional(c.default || c))
export const CoverGradient = () => import('../../components/cover/Gradient.vue' /* webpackChunkName: "components/cover-gradient" */).then(c => wrapFunctional(c.default || c))
export const CtaEmail = () => import('../../components/cta/Email.vue' /* webpackChunkName: "components/cta-email" */).then(c => wrapFunctional(c.default || c))
export const CtaExtAndApp = () => import('../../components/cta/ExtAndApp.vue' /* webpackChunkName: "components/cta-ext-and-app" */).then(c => wrapFunctional(c.default || c))
export const CtaHandler = () => import('../../components/cta/Handler.vue' /* webpackChunkName: "components/cta-handler" */).then(c => wrapFunctional(c.default || c))
export const CtaFacebook = () => import('../../components/cta/facebook.vue' /* webpackChunkName: "components/cta-facebook" */).then(c => wrapFunctional(c.default || c))
export const CtaTelegram = () => import('../../components/cta/telegram.vue' /* webpackChunkName: "components/cta-telegram" */).then(c => wrapFunctional(c.default || c))
export const CtaWhatsapp = () => import('../../components/cta/whatsapp.vue' /* webpackChunkName: "components/cta-whatsapp" */).then(c => wrapFunctional(c.default || c))
export const DropdownConfirmAction = () => import('../../components/dropdown/ConfirmAction.vue' /* webpackChunkName: "components/dropdown-confirm-action" */).then(c => wrapFunctional(c.default || c))
export const DropdownDefault = () => import('../../components/dropdown/Default.vue' /* webpackChunkName: "components/dropdown-default" */).then(c => wrapFunctional(c.default || c))
export const DropdownLocation = () => import('../../components/dropdown/Location.vue' /* webpackChunkName: "components/dropdown-location" */).then(c => wrapFunctional(c.default || c))
export const DropdownMaincard = () => import('../../components/dropdown/Maincard.vue' /* webpackChunkName: "components/dropdown-maincard" */).then(c => wrapFunctional(c.default || c))
export const DropdownMenu = () => import('../../components/dropdown/Menu.vue' /* webpackChunkName: "components/dropdown-menu" */).then(c => wrapFunctional(c.default || c))
export const DropdownOptions = () => import('../../components/dropdown/Options.vue' /* webpackChunkName: "components/dropdown-options" */).then(c => wrapFunctional(c.default || c))
export const DropdownOrderby = () => import('../../components/dropdown/Orderby.vue' /* webpackChunkName: "components/dropdown-orderby" */).then(c => wrapFunctional(c.default || c))
export const DropdownUser = () => import('../../components/dropdown/User.vue' /* webpackChunkName: "components/dropdown-user" */).then(c => wrapFunctional(c.default || c))
export const FilterByLetter = () => import('../../components/filter/ByLetter.vue' /* webpackChunkName: "components/filter-by-letter" */).then(c => wrapFunctional(c.default || c))
export const FilterTabs = () => import('../../components/filter/Tabs.vue' /* webpackChunkName: "components/filter-tabs" */).then(c => wrapFunctional(c.default || c))
export const FormEmailLoginStrategy = () => import('../../components/form/EmailLoginStrategy.vue' /* webpackChunkName: "components/form-email-login-strategy" */).then(c => wrapFunctional(c.default || c))
export const FormForgotPassword = () => import('../../components/form/ForgotPassword.vue' /* webpackChunkName: "components/form-forgot-password" */).then(c => wrapFunctional(c.default || c))
export const FormLogin = () => import('../../components/form/Login.vue' /* webpackChunkName: "components/form-login" */).then(c => wrapFunctional(c.default || c))
export const FormMakeComment = () => import('../../components/form/MakeComment.vue' /* webpackChunkName: "components/form-make-comment" */).then(c => wrapFunctional(c.default || c))
export const FormQuill = () => import('../../components/form/Quill.vue' /* webpackChunkName: "components/form-quill" */).then(c => wrapFunctional(c.default || c))
export const FormQuillBig = () => import('../../components/form/QuillBig.vue' /* webpackChunkName: "components/form-quill-big" */).then(c => wrapFunctional(c.default || c))
export const FormRegister = () => import('../../components/form/Register.vue' /* webpackChunkName: "components/form-register" */).then(c => wrapFunctional(c.default || c))
export const LayoutFooter = () => import('../../components/layout/Footer.vue' /* webpackChunkName: "components/layout-footer" */).then(c => wrapFunctional(c.default || c))
export const LayoutHeader = () => import('../../components/layout/Header.vue' /* webpackChunkName: "components/layout-header" */).then(c => wrapFunctional(c.default || c))
export const LayoutInfoPages = () => import('../../components/layout/InfoPages.vue' /* webpackChunkName: "components/layout-info-pages" */).then(c => wrapFunctional(c.default || c))
export const LayoutMenu = () => import('../../components/layout/Menu.vue' /* webpackChunkName: "components/layout-menu" */).then(c => wrapFunctional(c.default || c))
export const LayoutModal = () => import('../../components/layout/Modal.vue' /* webpackChunkName: "components/layout-modal" */).then(c => wrapFunctional(c.default || c))
export const LayoutModalSecondary = () => import('../../components/layout/ModalSecondary.vue' /* webpackChunkName: "components/layout-modal-secondary" */).then(c => wrapFunctional(c.default || c))
export const LayoutOverlay = () => import('../../components/layout/Overlay.vue' /* webpackChunkName: "components/layout-overlay" */).then(c => wrapFunctional(c.default || c))
export const LayoutScrollTop = () => import('../../components/layout/ScrollTop.vue' /* webpackChunkName: "components/layout-scroll-top" */).then(c => wrapFunctional(c.default || c))
export const LayoutBanner = () => import('../../components/layout/banner.vue' /* webpackChunkName: "components/layout-banner" */).then(c => wrapFunctional(c.default || c))
export const ListDeals = () => import('../../components/list/Deals.vue' /* webpackChunkName: "components/list-deals" */).then(c => wrapFunctional(c.default || c))
export const ListLottery = () => import('../../components/list/Lottery.vue' /* webpackChunkName: "components/list-lottery" */).then(c => wrapFunctional(c.default || c))
export const ListThreads = () => import('../../components/list/Threads.vue' /* webpackChunkName: "components/list-threads" */).then(c => wrapFunctional(c.default || c))
export const MicroBadge = () => import('../../components/micro/Badge.vue' /* webpackChunkName: "components/micro-badge" */).then(c => wrapFunctional(c.default || c))
export const MicroBreadcrumbs = () => import('../../components/micro/Breadcrumbs.vue' /* webpackChunkName: "components/micro-breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const MicroCategoriesChips = () => import('../../components/micro/CategoriesChips.vue' /* webpackChunkName: "components/micro-categories-chips" */).then(c => wrapFunctional(c.default || c))
export const MicroDate = () => import('../../components/micro/Date.vue' /* webpackChunkName: "components/micro-date" */).then(c => wrapFunctional(c.default || c))
export const MicroGlobalLoader = () => import('../../components/micro/GlobalLoader.vue' /* webpackChunkName: "components/micro-global-loader" */).then(c => wrapFunctional(c.default || c))
export const MicroHeatCounter = () => import('../../components/micro/HeatCounter.vue' /* webpackChunkName: "components/micro-heat-counter" */).then(c => wrapFunctional(c.default || c))
export const MicroHeatCounterVote = () => import('../../components/micro/HeatCounterVote.vue' /* webpackChunkName: "components/micro-heat-counter-vote" */).then(c => wrapFunctional(c.default || c))
export const MicroLazy = () => import('../../components/micro/Lazy.vue' /* webpackChunkName: "components/micro-lazy" */).then(c => wrapFunctional(c.default || c))
export const MicroNoResults = () => import('../../components/micro/NoResults.vue' /* webpackChunkName: "components/micro-no-results" */).then(c => wrapFunctional(c.default || c))
export const MicroPrice = () => import('../../components/micro/Price.vue' /* webpackChunkName: "components/micro-price" */).then(c => wrapFunctional(c.default || c))
export const MicroQuestion = () => import('../../components/micro/Question.vue' /* webpackChunkName: "components/micro-question" */).then(c => wrapFunctional(c.default || c))
export const MicroSlider = () => import('../../components/micro/Slider.vue' /* webpackChunkName: "components/micro-slider" */).then(c => wrapFunctional(c.default || c))
export const MicroSliderItem = () => import('../../components/micro/SliderItem.vue' /* webpackChunkName: "components/micro-slider-item" */).then(c => wrapFunctional(c.default || c))
export const MicroStepper = () => import('../../components/micro/Stepper.vue' /* webpackChunkName: "components/micro-stepper" */).then(c => wrapFunctional(c.default || c))
export const MicroStoreServerContent = () => import('../../components/micro/StoreServerContent.vue' /* webpackChunkName: "components/micro-store-server-content" */).then(c => wrapFunctional(c.default || c))
export const MicroStoreShare = () => import('../../components/micro/StoreShare.vue' /* webpackChunkName: "components/micro-store-share" */).then(c => wrapFunctional(c.default || c))
export const MicroStoreSummary = () => import('../../components/micro/StoreSummary.vue' /* webpackChunkName: "components/micro-store-summary" */).then(c => wrapFunctional(c.default || c))
export const MicroTabs = () => import('../../components/micro/Tabs.vue' /* webpackChunkName: "components/micro-tabs" */).then(c => wrapFunctional(c.default || c))
export const MicroTooltip = () => import('../../components/micro/Tooltip.vue' /* webpackChunkName: "components/micro-tooltip" */).then(c => wrapFunctional(c.default || c))
export const MicroDiscountFooter = () => import('../../components/micro/discountFooter.vue' /* webpackChunkName: "components/micro-discount-footer" */).then(c => wrapFunctional(c.default || c))
export const MicroFullUser = () => import('../../components/micro/fullUser.vue' /* webpackChunkName: "components/micro-full-user" */).then(c => wrapFunctional(c.default || c))
export const MicroUserImage = () => import('../../components/micro/userImage.vue' /* webpackChunkName: "components/micro-user-image" */).then(c => wrapFunctional(c.default || c))
export const MicroUserNoImage = () => import('../../components/micro/userNoImage.vue' /* webpackChunkName: "components/micro-user-no-image" */).then(c => wrapFunctional(c.default || c))
export const ModalConfirm = () => import('../../components/modal/Confirm.vue' /* webpackChunkName: "components/modal-confirm" */).then(c => wrapFunctional(c.default || c))
export const ModalCoupon = () => import('../../components/modal/Coupon.vue' /* webpackChunkName: "components/modal-coupon" */).then(c => wrapFunctional(c.default || c))
export const ModalGeneric = () => import('../../components/modal/Generic.vue' /* webpackChunkName: "components/modal-generic" */).then(c => wrapFunctional(c.default || c))
export const ModalImageZoom = () => import('../../components/modal/ImageZoom.vue' /* webpackChunkName: "components/modal-image-zoom" */).then(c => wrapFunctional(c.default || c))
export const SidebarBanners = () => import('../../components/sidebar/Banners.vue' /* webpackChunkName: "components/sidebar-banners" */).then(c => wrapFunctional(c.default || c))
export const SidebarCategoriesTwo = () => import('../../components/sidebar/CategoriesTwo.vue' /* webpackChunkName: "components/sidebar-categories-two" */).then(c => wrapFunctional(c.default || c))
export const SidebarCategoriesTwoLazy = () => import('../../components/sidebar/CategoriesTwoLazy.vue' /* webpackChunkName: "components/sidebar-categories-two-lazy" */).then(c => wrapFunctional(c.default || c))
export const SidebarDescription = () => import('../../components/sidebar/Description.vue' /* webpackChunkName: "components/sidebar-description" */).then(c => wrapFunctional(c.default || c))
export const SidebarFeaturedCoupons = () => import('../../components/sidebar/FeaturedCoupons.vue' /* webpackChunkName: "components/sidebar-featured-coupons" */).then(c => wrapFunctional(c.default || c))
export const SidebarGiveMe = () => import('../../components/sidebar/GiveMe.vue' /* webpackChunkName: "components/sidebar-give-me" */).then(c => wrapFunctional(c.default || c))
export const SidebarLinks = () => import('../../components/sidebar/Links.vue' /* webpackChunkName: "components/sidebar-links" */).then(c => wrapFunctional(c.default || c))
export const SidebarNetworkPages = () => import('../../components/sidebar/NetworkPages.vue' /* webpackChunkName: "components/sidebar-network-pages" */).then(c => wrapFunctional(c.default || c))
export const SidebarOfferSummary = () => import('../../components/sidebar/OfferSummary.vue' /* webpackChunkName: "components/sidebar-offer-summary" */).then(c => wrapFunctional(c.default || c))
export const SidebarOfferSummaryLazy = () => import('../../components/sidebar/OfferSummaryLazy.vue' /* webpackChunkName: "components/sidebar-offer-summary-lazy" */).then(c => wrapFunctional(c.default || c))
export const SidebarOfferSummarySkeleton = () => import('../../components/sidebar/OfferSummarySkeleton.vue' /* webpackChunkName: "components/sidebar-offer-summary-skeleton" */).then(c => wrapFunctional(c.default || c))
export const SidebarRatingStars = () => import('../../components/sidebar/RatingStars.vue' /* webpackChunkName: "components/sidebar-rating-stars" */).then(c => wrapFunctional(c.default || c))
export const SidebarShare = () => import('../../components/sidebar/Share.vue' /* webpackChunkName: "components/sidebar-share" */).then(c => wrapFunctional(c.default || c))
export const SidebarSimilar = () => import('../../components/sidebar/Similar.vue' /* webpackChunkName: "components/sidebar-similar" */).then(c => wrapFunctional(c.default || c))
export const SidebarSocialNetworks = () => import('../../components/sidebar/SocialNetworks.vue' /* webpackChunkName: "components/sidebar-social-networks" */).then(c => wrapFunctional(c.default || c))
export const SidebarSocialNetworksLazy = () => import('../../components/sidebar/SocialNetworksLazy.vue' /* webpackChunkName: "components/sidebar-social-networks-lazy" */).then(c => wrapFunctional(c.default || c))
export const SidebarSocialNetworksSkeleton = () => import('../../components/sidebar/SocialNetworksSkeleton.vue' /* webpackChunkName: "components/sidebar-social-networks-skeleton" */).then(c => wrapFunctional(c.default || c))
export const SidebarStores = () => import('../../components/sidebar/Stores.vue' /* webpackChunkName: "components/sidebar-stores" */).then(c => wrapFunctional(c.default || c))
export const SidebarStoresLazy = () => import('../../components/sidebar/StoresLazy.vue' /* webpackChunkName: "components/sidebar-stores-lazy" */).then(c => wrapFunctional(c.default || c))
export const SidebarStoresSkeleton = () => import('../../components/sidebar/StoresSkeleton.vue' /* webpackChunkName: "components/sidebar-stores-skeleton" */).then(c => wrapFunctional(c.default || c))
export const SidebarStoresTwo = () => import('../../components/sidebar/StoresTwo.vue' /* webpackChunkName: "components/sidebar-stores-two" */).then(c => wrapFunctional(c.default || c))
export const SidebarStoresTwoLazy = () => import('../../components/sidebar/StoresTwoLazy.vue' /* webpackChunkName: "components/sidebar-stores-two-lazy" */).then(c => wrapFunctional(c.default || c))
export const SidebarStoresTwoSkeleton = () => import('../../components/sidebar/StoresTwoSkeleton.vue' /* webpackChunkName: "components/sidebar-stores-two-skeleton" */).then(c => wrapFunctional(c.default || c))
export const SidebarUserRanking = () => import('../../components/sidebar/UserRanking.vue' /* webpackChunkName: "components/sidebar-user-ranking" */).then(c => wrapFunctional(c.default || c))
export const CardDashboardActivity = () => import('../../components/card/dashboard/Activity.vue' /* webpackChunkName: "components/card-dashboard-activity" */).then(c => wrapFunctional(c.default || c))
export const CardDashboardCoin = () => import('../../components/card/dashboard/Coin.vue' /* webpackChunkName: "components/card-dashboard-coin" */).then(c => wrapFunctional(c.default || c))
export const CardDashboardLottery = () => import('../../components/card/dashboard/Lottery.vue' /* webpackChunkName: "components/card-dashboard-lottery" */).then(c => wrapFunctional(c.default || c))
export const CardDashboardRouter = () => import('../../components/card/dashboard/Router.vue' /* webpackChunkName: "components/card-dashboard-router" */).then(c => wrapFunctional(c.default || c))
export const CardDashboardThread = () => import('../../components/card/dashboard/Thread.vue' /* webpackChunkName: "components/card-dashboard-thread" */).then(c => wrapFunctional(c.default || c))
export const CardMainCoupons = () => import('../../components/card/main/Coupons.vue' /* webpackChunkName: "components/card-main-coupons" */).then(c => wrapFunctional(c.default || c))
export const CardMainGlobal = () => import('../../components/card/main/Global.vue' /* webpackChunkName: "components/card-main-global" */).then(c => wrapFunctional(c.default || c))
export const CardMainGlobalFeatured = () => import('../../components/card/main/GlobalFeatured.vue' /* webpackChunkName: "components/card-main-global-featured" */).then(c => wrapFunctional(c.default || c))
export const CardMainLottery = () => import('../../components/card/main/Lottery.vue' /* webpackChunkName: "components/card-main-lottery" */).then(c => wrapFunctional(c.default || c))
export const CardMainSidebarDiscount = () => import('../../components/card/main/SidebarDiscount.vue' /* webpackChunkName: "components/card-main-sidebar-discount" */).then(c => wrapFunctional(c.default || c))
export const CardShareStepOne = () => import('../../components/card/share/StepOne.vue' /* webpackChunkName: "components/card-share-step-one" */).then(c => wrapFunctional(c.default || c))
export const CardShareStepTwo = () => import('../../components/card/share/StepTwo.vue' /* webpackChunkName: "components/card-share-step-two" */).then(c => wrapFunctional(c.default || c))
export const DropdownDetailsCommentOptions = () => import('../../components/dropdown/Details/CommentOptions.vue' /* webpackChunkName: "components/dropdown-details-comment-options" */).then(c => wrapFunctional(c.default || c))
export const DropdownDetailsCommentsOrder = () => import('../../components/dropdown/Details/CommentsOrder.vue' /* webpackChunkName: "components/dropdown-details-comments-order" */).then(c => wrapFunctional(c.default || c))
export const DropdownDetailsReport = () => import('../../components/dropdown/Details/Report.vue' /* webpackChunkName: "components/dropdown-details-report" */).then(c => wrapFunctional(c.default || c))
export const DropdownShareSelect = () => import('../../components/dropdown/Share/Select.vue' /* webpackChunkName: "components/dropdown-share-select" */).then(c => wrapFunctional(c.default || c))
export const FormFieldAlert = () => import('../../components/form/field/Alert.vue' /* webpackChunkName: "components/form-field-alert" */).then(c => wrapFunctional(c.default || c))
export const FormFieldAvatarSelect = () => import('../../components/form/field/AvatarSelect.vue' /* webpackChunkName: "components/form-field-avatar-select" */).then(c => wrapFunctional(c.default || c))
export const FormFieldCheckbox = () => import('../../components/form/field/Checkbox.vue' /* webpackChunkName: "components/form-field-checkbox" */).then(c => wrapFunctional(c.default || c))
export const FormFieldGeneric = () => import('../../components/form/field/Generic.vue' /* webpackChunkName: "components/form-field-generic" */).then(c => wrapFunctional(c.default || c))
export const FormFieldGiphy = () => import('../../components/form/field/Giphy.vue' /* webpackChunkName: "components/form-field-giphy" */).then(c => wrapFunctional(c.default || c))
export const FormFieldPassword = () => import('../../components/form/field/Password.vue' /* webpackChunkName: "components/form-field-password" */).then(c => wrapFunctional(c.default || c))
export const FormFieldPrice = () => import('../../components/form/field/Price.vue' /* webpackChunkName: "components/form-field-price" */).then(c => wrapFunctional(c.default || c))
export const FormFieldSelect = () => import('../../components/form/field/Select.vue' /* webpackChunkName: "components/form-field-select" */).then(c => wrapFunctional(c.default || c))
export const FormFieldTabs = () => import('../../components/form/field/Tabs.vue' /* webpackChunkName: "components/form-field-tabs" */).then(c => wrapFunctional(c.default || c))
export const FormFieldTextArea = () => import('../../components/form/field/TextArea.vue' /* webpackChunkName: "components/form-field-text-area" */).then(c => wrapFunctional(c.default || c))
export const FormFieldToggle = () => import('../../components/form/field/Toggle.vue' /* webpackChunkName: "components/form-field-toggle" */).then(c => wrapFunctional(c.default || c))
export const LayoutHeaderAdBars = () => import('../../components/layout/header/AdBars.vue' /* webpackChunkName: "components/layout-header-ad-bars" */).then(c => wrapFunctional(c.default || c))
export const LayoutHeaderArcLinks = () => import('../../components/layout/header/ArcLinks.vue' /* webpackChunkName: "components/layout-header-arc-links" */).then(c => wrapFunctional(c.default || c))
export const LayoutHeaderBanner = () => import('../../components/layout/header/Banner.vue' /* webpackChunkName: "components/layout-header-banner" */).then(c => wrapFunctional(c.default || c))
export const LayoutHeaderFloatingResume = () => import('../../components/layout/header/FloatingResume.vue' /* webpackChunkName: "components/layout-header-floating-resume" */).then(c => wrapFunctional(c.default || c))
export const LayoutHeaderLinksWarning = () => import('../../components/layout/header/LinksWarning.vue' /* webpackChunkName: "components/layout-header-links-warning" */).then(c => wrapFunctional(c.default || c))
export const LayoutHeaderMenu = () => import('../../components/layout/header/Menu.vue' /* webpackChunkName: "components/layout-header-menu" */).then(c => wrapFunctional(c.default || c))
export const LayoutHeaderNotifications = () => import('../../components/layout/header/Notifications.vue' /* webpackChunkName: "components/layout-header-notifications" */).then(c => wrapFunctional(c.default || c))
export const LayoutHeaderSearch = () => import('../../components/layout/header/Search.vue' /* webpackChunkName: "components/layout-header-search" */).then(c => wrapFunctional(c.default || c))
export const LayoutHeaderSuggestions = () => import('../../components/layout/header/Suggestions.vue' /* webpackChunkName: "components/layout-header-suggestions" */).then(c => wrapFunctional(c.default || c))
export const LayoutHeaderUser = () => import('../../components/layout/header/User.vue' /* webpackChunkName: "components/layout-header-user" */).then(c => wrapFunctional(c.default || c))
export const ModalAuthAcceptTerms = () => import('../../components/modal/auth/AcceptTerms.vue' /* webpackChunkName: "components/modal-auth-accept-terms" */).then(c => wrapFunctional(c.default || c))
export const ModalAuthEmailVerification = () => import('../../components/modal/auth/EmailVerification.vue' /* webpackChunkName: "components/modal-auth-email-verification" */).then(c => wrapFunctional(c.default || c))
export const ModalAuthLogin = () => import('../../components/modal/auth/Login.vue' /* webpackChunkName: "components/modal-auth-login" */).then(c => wrapFunctional(c.default || c))
export const ModalAuthRegister = () => import('../../components/modal/auth/Register.vue' /* webpackChunkName: "components/modal-auth-register" */).then(c => wrapFunctional(c.default || c))
export const ModalAuthResetPasswordInit = () => import('../../components/modal/auth/ResetPasswordInit.vue' /* webpackChunkName: "components/modal-auth-reset-password-init" */).then(c => wrapFunctional(c.default || c))
export const ModalCookiesAdjust = () => import('../../components/modal/cookies/Adjust.vue' /* webpackChunkName: "components/modal-cookies-adjust" */).then(c => wrapFunctional(c.default || c))
export const ModalCookies = () => import('../../components/modal/cookies/Cookies.vue' /* webpackChunkName: "components/modal-cookies" */).then(c => wrapFunctional(c.default || c))
export const ModalDashboardEmail = () => import('../../components/modal/dashboard/Email.vue' /* webpackChunkName: "components/modal-dashboard-email" */).then(c => wrapFunctional(c.default || c))
export const ModalDashboardPassword = () => import('../../components/modal/dashboard/Password.vue' /* webpackChunkName: "components/modal-dashboard-password" */).then(c => wrapFunctional(c.default || c))
export const ModalDashboardUsername = () => import('../../components/modal/dashboard/Username.vue' /* webpackChunkName: "components/modal-dashboard-username" */).then(c => wrapFunctional(c.default || c))
export const ModalMessagesNewMessage = () => import('../../components/modal/messages/NewMessage.vue' /* webpackChunkName: "components/modal-messages-new-message" */).then(c => wrapFunctional(c.default || c))
export const CardSharePreviewChat = () => import('../../components/card/share/preview/Chat.vue' /* webpackChunkName: "components/card-share-preview-chat" */).then(c => wrapFunctional(c.default || c))
export const CardSharePreviewLottery = () => import('../../components/card/share/preview/Lottery.vue' /* webpackChunkName: "components/card-share-preview-lottery" */).then(c => wrapFunctional(c.default || c))
export const CardSharePreviewOfferAndCoupon = () => import('../../components/card/share/preview/OfferAndCoupon.vue' /* webpackChunkName: "components/card-share-preview-offer-and-coupon" */).then(c => wrapFunctional(c.default || c))
export const CardSliderEventFeaturedDiscounts = () => import('../../components/card/slider/event/FeaturedDiscounts.vue' /* webpackChunkName: "components/card-slider-event-featured-discounts" */).then(c => wrapFunctional(c.default || c))
export const CardSliderEventFeaturedStores = () => import('../../components/card/slider/event/FeaturedStores.vue' /* webpackChunkName: "components/card-slider-event-featured-stores" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
