
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { remove as removeCookie } from 'js-cookie'
import { cookies } from '~/config/constants'

export default Vue.extend({
    name: 'LayoutHeaderUser',
    data() {
        return {
            showMenu: false,
        }
    },
    computed: {
        ...mapGetters({ sessionStatus: 'auth/isAuth', userData: 'user/getProfile' }),
        notificationsAndMessagesCount(): number {
            return this.$store.state.user.notificationsCount + this.$store.state.user.messagesCount
        },
        isLoggedIn(): boolean {
            return this.sessionStatus && !!this.userData
        },
        options(): any[] {
            return [
                {
                    title: this.$util.lang.components.layoutHeaderUser.dashboard,
                    icon: {
                        active: this.$assets.primary.userAccount,
                        inactive: this.$assets.gray.userAccount,
                    },
                    path: `/${this.$util.lang.routes.dashboard}`,
                },
                {
                    title: this.$util.lang.components.layoutHeaderUser.notifications,
                    icon: {
                        active: this.$assets.primary.userNotifications,
                        inactive: this.$assets.gray.userNotifications,
                    },
                    notifications: this.$store.state.user.notificationsCount,
                    path: `/${this.$util.lang.routes.notifications}`,
                },
                {
                    title: this.$util.lang.components.layoutHeaderUser.messages,
                    icon: {
                        active: this.$assets.primary.userMessages,
                        inactive: this.$assets.gray.userMessages,
                    },
                    notifications: this.$store.state.user.messagesCount,
                    path: `/${this.$util.lang.routes.inbox}`,
                },
                {
                    title: this.$util.lang.components.layoutHeaderUser.saved,
                    icon: { active: this.$assets.primary.userSaved, inactive: this.$assets.gray.userSaved },
                    path: `/${this.$util.lang.routes.dashboard}/${this.$util.lang.routes.saved}`,
                },
                {
                    title: this.$util.lang.components.layoutHeaderUser.coins,
                    icon: { active: this.$assets.primary.userCoins, inactive: this.$assets.gray.userCoins },
                    path: `/${this.$util.lang.routes.dashboard}/${this.$util.lang.routes.coins}`,
                },
                {
                    title: this.$util.lang.components.layoutHeaderUser.rewards,
                    icon: {
                        active: this.$assets.primary.userThrophies,
                        inactive: this.$assets.gray.userThrophies,
                    },
                    path: `/${this.$util.lang.routes.dashboard}/${this.$util.lang.routes.rewards}`,
                },
                {
                    title: this.$util.lang.components.layoutHeaderUser.settings,
                    icon: {
                        active: this.$assets.primary.userSettings,
                        inactive: this.$assets.gray.userSettings,
                    },
                    path: `/${this.$util.lang.routes.dashboard}/${this.$util.lang.routes.settings}`,
                },
                {
                    title: this.$util.lang.components.layoutHeaderUser.close_session,
                    icon: this.$assets.gray.userLogout,
                    handler: () => {
                        this.toggleMenu(false)
                        this.signOut()
                    },
                },
            ]
        },
    },
    watch: {
        $route: {
            deep: true,
            handler() {
                this.toggleMenu(false)
            },
        },
    },

    methods: {
        toggleMenu(show: boolean) {
            this.showMenu = show
        },
        signIn(): void {
            if (this.$route.name === 'login') {
                this.$root.$emit('notification', {
                    duration: 5000,
                    text: this.$util.lang.components.layoutHeaderUser.in_close_session,
                    type: 'help',
                })
                return
            }
            if (this.$route.name === 'registro') {
                this.$router.push(`/${this.$util.lang.routes.login}`)
                return
            }

            this.$store.commit('setModal', { name: 'ModalAuthLogin' })
        },
        async signOut() {
            this.$nuxt.$loading.start()

            const result = await this.$api.auth.signOut()

            removeCookie(cookies.access_token_key)
            removeCookie(cookies.token_expires_at_key)

            if (this.$route.name === 'index') {
                window.location.reload()
            } else {
                const notification =
                    result?.feedback === 'closed_session_success'
                        ? result.data.message
                        : this.$util.lang.components.layoutHeaderUser.session_closed

                this.$root.$emit('notification', {
                    duration: 4000,
                    text: notification,
                    type: result?.feedback === 'closed_session_success' ? 'success' : 'warn',
                })

                this.$store.commit('auth/setAuthenticationStatus', false)
                this.$store.commit('auth/setAuthData', null)

                this.$store.commit('auth/setNextNavigationHandler', () => {
                    this.$store.commit('user/setProfile', null)

                    this.$store.commit('auth/setNextNavigationHandler', null)
                })
                this.$router.push({ name: 'index', query: {} })
            }
        },
    },
})
