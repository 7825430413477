import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { AxiosError } from 'axios'
import { stringify } from 'qs'
import endpoints from '~/api/endpoints/authentication'

export default (AxiosInstance: NuxtAxiosInstance) => ({
    signUp: (body: { email: string; password: string; name: string }) => {
        return AxiosInstance.$post(endpoints.signUp.path, body).catch((e: AxiosError) => e.response?.data)
    },

    facebookCallback: (token: string) => {
        return AxiosInstance.$get(endpoints.socialStrategy.facebook, {
            params: {
                access_token: token,
            },
        }).catch((e: AxiosError) => e.response?.data)
    },

    googleCallback: (token: string) => {
        return AxiosInstance.$get(endpoints.socialStrategy.google, {
            params: {
                access_token: token,
            },
        }).catch((e: AxiosError) => e.response?.data)
    },

    signIn: (
        strategy: 'withPassword' | 'withEmailCode',
        body: {
            email: string
            password?: string
            code?: string
        }
    ) => {
        return AxiosInstance.$post(endpoints.signIn[strategy].path, body).catch(
            (e: AxiosError) => e.response?.data
        )
    },

    signOut: () => {
        return AxiosInstance.$get(endpoints.signOut.path).catch((e: AxiosError) => e.response?.data)
    },

    refreshSession: () => {
        return AxiosInstance.$get(endpoints.refresh.path).catch((e: AxiosError) => e.response?.data)
    },

    accountVerification: (body: { code: string }) => {
        return AxiosInstance.$post(endpoints.verify.path, body)
    },

    recoveryInit: (
        strategy: 'withCode' | 'withToken',
        body: {
            email: string
        }
    ) => {
        return AxiosInstance.$post(endpoints.recoveryInit[strategy].path, body).catch(
            (e: AxiosError) => e.response?.data
        )
    },

    recoveryFinish: (
        strategy: 'withToken',
        body: {
            password: string
            password_confirmation: string
            token: string
        }
    ) => {
        return AxiosInstance.$post(endpoints.recoveryFinish[strategy].path, body).catch(
            (e: AxiosError) => e.response?.data
        )
    },

    socialSingIn: (
        strategy: 'google' | 'facebook',
        params: {
            success_url: string
            error_url: string
            user_id?: number
        }
    ) => {
        window.open(
            `${endpoints.socialStrategy.path.replace('_RRSS_', strategy)}?${stringify(params)}`,
            '_self'
        )
    },
})
