
import Vue from 'vue'

type NotificationInit = {
    text: string
    dismissButtonText: string
    type: 'warning' | 'error' | 'success' | 'help'
    duration: number
    fromServer: boolean
}

export default Vue.extend({
    name: 'LayoutHeaderNotifications',
    data() {
        return {
            mount: false,
            timer: null as null | NodeJS.Timeout,
            init: {
                default: {
                    duration: 6000,
                    type: 'success',
                    dismissButtonText: 'Cerrar',
                },
                custom: null as null | NotificationInit,
            },
            stack: [] as NotificationInit[],
        }
    },
    computed: {
        serverNotification(): NotificationInit {
            return this.$store.state.serverNotification
        },
    },
    created() {
        process.browser &&
            this.$root.$on('notification', (config: NotificationInit) => {
                setTimeout(() => {
                    config?.text?.trim().length && this.createNotification(config)
                }, 300)
            })
    },
    mounted() {
        if (this.serverNotification) {
            this.serverNotification?.text?.trim().length && this.createNotification(this.serverNotification)
        }
    },
    methods: {
        createNotification(config: NotificationInit): void {
            const notification = {
                ...this.init.default,
                ...config,
            }

            if (this.init.custom && this.timer) {
                if (this.init.custom.text !== notification.text) {
                    this.stack = [...this.stack, notification]
                }
            } else {
                this.mountNotification(notification, true)
            }
        },
        mountNotification(notification: NotificationInit, handleClasses: boolean) {
            this.init.custom = notification

            this.mount = true

            if (handleClasses) {
                this.$emit('prevent-scroll-listeners')

                document.body.classList.add('add-notifications-height')
            }

            this.timer = setTimeout(() => this.dismiss(), this.init.custom.duration)
        },
        dismiss(): void {
            if (this.timer) {
                clearTimeout(this.timer)

                this.timer = null
            }

            if (this.init.custom?.fromServer) {
                this.$store.commit('setServerNotification', null)
            }

            if (this.stack.length) {
                const nextNotification = this.stack.pop() as NotificationInit
                this.mountNotification(nextNotification, false)
            } else {
                this.$emit('prevent-scroll-listeners')

                document.body.classList.remove('add-notifications-height')

                setTimeout(() => {
                    this.mount = false

                    this.init.custom = null
                }, 350)
            }
        },
    },
})
