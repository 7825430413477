
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { Models } from '~/types/models'
/* import { cookies as cookiesKeys } from '@/config/constants' */

export default Vue.extend({
    name: 'LayoutDefault',
    data() {
        return {
            mode: '',
            mounted: false,
        }
    },
    head() {
        const adbarsDesktop = this.layoutInfo?.header.adbar?.desktop
        const adbarsMobile = this.layoutInfo?.header.adbar?.mobile

        return {
            bodyAttrs: {
                class:
                    (this.layoutInfo?.header.adbar ? 'add-adbars-height' : '') +
                    (adbarsDesktop ? ' show-desktop-adbars' : '') +
                    (adbarsMobile ? ' show-mobile-adbars' : ''),
            },
        }
    },
    computed: {
        ...mapGetters({
            layoutInfo: 'getLayout',
            modal: 'getModal',
            modalSecondary: 'getModalSecondary',
            banners: 'getBanners',
            menu: 'getDropdown',
            isAuth: 'auth/isAuth',
        }),
        showFloatingResume(): boolean {
            return (
                !!this.discount &&
                /^(ofertas|cupones)-slug$/.test(this.$route.name as string) &&
                /^(coupon|offer|offer-code)$/.test(this.mode)
            )
        },
        discount(): Models.Discount | null {
            return this.$store.state.pages.discount?.offer || this.$store.state.pages.discount?.coupon
        },
    },
    watch: {
        discount: {
            deep: true,
            immediate: true,
            handler(discount: Models.Discount | null) {
                if (discount) {
                    if (discount.type === 'offer') {
                        if (discount.has_url) {
                            if (discount.code) {
                                this.mode = 'offer-code'
                            } else {
                                this.mode = 'offer'
                            }
                        } else {
                            this.mode = 'no-link-offer'
                        }
                    } else if (discount.type === 'coupon') {
                        if (discount.has_url) {
                            this.mode = 'coupon'
                        } else {
                            this.mode = 'no-link-coupon'
                        }
                    }
                }
            },
        },
        '$route.path'() {
            this.$nextTick(() => this.$store.state.auth.nextNavigationHandler?.())
        },
        isAuth: {
            handler(newValue) {
                if (process.client && newValue) {
                    const ls = localStorage.getItem('action')
                    if (ls) {
                        this.$router.push({
                            name: 'preferences',
                            query: { action: ls },
                            hash: '#notificaciones',
                        })
                    }
                }
            },
            immediate: true,
        },
    },
    mounted() {
        this.$nextTick(() => {
            setInterval(() => {
                this.getNotifications()
            }, 60000)

            /* if (this.$store.state.auth.isAuth) {
                setTimeout(async () => {
                    const refreshedToken = await this.$api.auth.refreshSession()

                    if (!refreshedToken.error) {
                        this.$store.commit('auth/setAuthData', refreshedToken.data)

                        this.$cookies.set(cookiesKeys.access_token_key, refreshedToken.data.access_token, {
                            expires: new Date(refreshedToken.data.expires_at * 1000),
                            path: '/',
                        })

                        this.$cookies.set(
                            cookiesKeys.token_expires_at_key,
                            String(refreshedToken.data.expires_at),
                            {
                                expires: new Date(refreshedToken.data.expires_at * 1000),
                                path: '/',
                            }
                        )
                    }
                }, 10000)
            } */

            this.mounted = true

            this.getNotifications()
        })
    },
    methods: {
        getNotifications() {
            if (this.isAuth) {
                this.$store.dispatch('user/getNotificationsCount', {
                    store: this.$store,
                    $api: this.$api,
                })
                this.$store.dispatch('user/getMessagesCount', {
                    store: this.$store,
                    $api: this.$api,
                })
            }
        },
    },
})
