import { Context } from '@nuxt/types'
import { MutationTree, ActionTree, ActionContext, GetterTree } from 'vuex/types'
import { cookies as cookiesKeys } from '~/config/constants'

const { access_token_key: token_key, token_expires_at_key: expires_at_key } = cookiesKeys

const initialAuthData = { access_token: '', token_type: 'Bearer', expires_at: 0 }

const setCookies = (appCtx: Context, cookies: { access_token: string; expires_at: number }) => {
    appCtx.$cookies.set(token_key, cookies.access_token, {
        expires: new Date(cookies.expires_at * 1000),
        path: '/',
    })

    appCtx.$cookies.set(expires_at_key, String(cookies.expires_at), {
        expires: new Date(cookies.expires_at * 1000),
        path: '/',
    })
}

export const state = () => ({
    isAuth: false,
    authData: initialAuthData,
    recoveryEmail: null as null | string,
    nextNavigationHandler: null as null | (() => void),
})

export type RootState = ReturnType<typeof state>

export const getters: GetterTree<RootState, RootState> = {
    isAuth({ isAuth }) {
        return isAuth
    },
}

export const mutations: MutationTree<RootState> = {
    setAuthenticationStatus: (state, newStatus: boolean) => {
        state.isAuth = newStatus
    },
    setAuthData: (state, newauthData: RootState['authData'] | null) => {
        state.authData = newauthData ? { ...state.authData, ...newauthData } : initialAuthData
    },
    setRecoveryEmail: (state, email: string | null) => {
        state.recoveryEmail = email
    },
    setNextNavigationHandler: (state, handler: RootState['nextNavigationHandler']) => {
        state.nextNavigationHandler = handler
    },
}

export const actions: ActionTree<RootState, RootState> = {
    authenticationInitializer: async (ctx: ActionContext<RootState, RootState>, appCtx: Context) => {
        const cookieToken = appCtx.$cookies.get(token_key)

        const cookieExpiration = appCtx.$cookies.get(expires_at_key)

        const queryToken = appCtx.query.token as string | null | undefined

        if (!queryToken && !cookieToken) return

        const clearAuthState = () => {
            ctx.commit('setAuthData', null)

            ctx.commit('setAuthenticationStatus', false)

            setCookies(appCtx, { access_token: '', expires_at: 0 })
        }

        if (cookieToken && cookieExpiration) {
            if (cookieExpiration * 1000 > Date.now()) {
                ctx.commit('setAuthData', {
                    access_token: cookieToken,
                    expires_at: Number(cookieExpiration),
                })

                try {
                    const refreshedToken = await appCtx.$api.auth.refreshSession()

                    if (!refreshedToken.error) {
                        ctx.commit('setAuthData', refreshedToken.data)

                        setCookies(appCtx, {
                            access_token: refreshedToken.data.access_token,
                            expires_at: refreshedToken.data.expires_at,
                        })
                    }

                    const userResult = await appCtx.$api.user.profile()

                    if (userResult.feedback === 'data_success') {
                        appCtx.store.commit('user/setProfile', userResult.data.user)

                        ctx.commit('setAuthenticationStatus', true)

                        if (userResult.data.user.accepted_conditions_at === null) {
                            appCtx.store.commit('setModal', {
                                name: 'ModalAuthAcceptTerms',
                                info: { message: null },
                            })
                        }
                    }

                    if (/^(token_expired|token_invalid)$/.test(userResult.feedback)) {
                        clearAuthState()
                    }
                } catch (e) {
                    clearAuthState()
                }
            } else {
                clearAuthState()
            }
        } else if (queryToken) {
            ctx.commit('setAuthData', { access_token: queryToken })

            try {
                const userResult = await appCtx.$api.user.profile()

                if (userResult.feedback === 'data_success') {
                    appCtx.store.commit('user/setProfile', userResult.data.user)

                    ctx.commit('setAuthData', { expires_at: userResult.data.token_expired_at })

                    setCookies(appCtx, {
                        access_token: ctx.state.authData.access_token,
                        expires_at: ctx.state.authData.expires_at,
                    })

                    ctx.commit('setAuthenticationStatus', true)

                    if (userResult.data.user.accepted_conditions_at === null) {
                        appCtx.store.commit('setModal', {
                            name: 'ModalAuthAcceptTerms',
                            info: { message: null },
                        })
                    }
                }

                if (/^(token_expired|token_invalid)$/.test(userResult.feedback)) {
                    clearAuthState()
                }
            } catch (e) {
                clearAuthState()
            }
        }
    },
}
