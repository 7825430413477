
import Vue from 'vue'

export default Vue.extend({
    name: 'LayoutOverlay',
    props: {
        mode: {
            type: String,
            default: 'normal',
        },
        handler: {
            type: Function,
            default: () => null,
        },
    },
})
