
import Vue from 'vue'

interface Links {
    outside: {
        path: string
        title: string
        icon: string
    }[]
    first: {
        path: string
        title: string
        icon: string
    }[]
    second: {
        path: string
        title: string
        icon: string
    }[]
}

export default Vue.extend({
    name: 'LayoutHeaderMenu',
    data() {
        return {
            showMenu: false,
        }
    },
    computed: {
        adminLinks():
            | {
                  color: number
                  external: number | boolean
                  icon: string | null
                  order: number
                  title: string
                  path: string
              }[]
            | null {
            return this.$store.state.layout?.header?.links?.map((link: any) => ({
                color: link.colour,
                external: link.external,
                icon: link.icon,
                order: link.order,
                title: link.title,
                path: link.url,
            }))
        },
        links(): Links {
            const links = {
                outside: [
                    {
                        title: this.$util.lang.components.layoutHeaderMenu.home,
                        path: '/',
                    },
                    {
                        title: this.$util.lang.components.layoutHeaderMenu.coupons,
                        path: `/${this.$util.lang.routes.coupons}`,
                    },
                    {
                        title: this.$util.lang.components.layoutHeaderMenu.offers,
                        path: `/${this.$util.lang.routes.offers}`,
                    },
                    {
                        title: this.$util.lang.components.layoutHeaderMenu.free,
                        path: `/${this.$util.lang.routes.free}`,
                    },
                ],
                first: [
                    {
                        title: this.$util.lang.components.layoutHeaderMenu.home,
                        icon: this.$assets.white.homeMenu,
                        path: '/',
                    },
                    {
                        title: this.$util.lang.components.layoutHeaderMenu.coupons,
                        icon: this.$assets.white.couponsMenu,
                        path: `/${this.$util.lang.routes.coupons}`,
                    },
                    {
                        title: this.$util.lang.components.layoutHeaderMenu.offers,
                        icon: this.$assets.white.offersMenu,
                        path: `/${this.$util.lang.routes.offers}`,
                    },
                    {
                        title: this.$util.lang.components.layoutHeaderMenu.free,
                        icon: this.$assets.white.freeMenu,
                        path: `/${this.$util.lang.routes.free}`,
                    },
                    {
                        title: this.$util.lang.components.layoutHeaderMenu.blog,
                        icon: this.$assets.white.pencilMenu,
                        path: `/${this.$util.lang.routes.blog}`,
                    },
                ],
                second: [
                    {
                        title: this.$util.lang.components.layoutHeaderMenu.categories,
                        icon: this.$assets.white.categoriesMenu,
                        path: `/${this.$util.lang.routes.categories}`,
                    },
                    {
                        title: this.$util.lang.components.layoutHeaderMenu.brands,
                        icon: this.$assets.white.brandsMenu,
                        path: `/${this.$util.lang.routes.brands}`,
                    },
                    {
                        title: this.$util.lang.components.layoutHeaderMenu.chats,
                        icon: this.$assets.white.chatMenu,
                        path: `/${this.$util.lang.routes.forum}`,
                    },
                    {
                        title: this.$util.lang.components.layoutHeaderMenu.novelties,
                        icon: this.$assets.white.newsMenu,
                        path: `/${this.$util.lang.routes.novelties}`,
                    },
                ],
            } as Links
            return links
        },
    },
    watch: {
        '$route.fullPath'() {
            this.toggleMenu(false)
        },
    },
    methods: {
        toggleMenu(show: boolean) {
            this.showMenu = show
        },
    },
})
