import { Plugin } from '@nuxt/types'
import { modules } from '@/api/index'
import { Api } from '@/global'

const plugin: Plugin = (ctx, inject) => {
    inject('api', {
        user: modules.user(ctx.$axios),
        pages: modules.pages(ctx.$axios),
        general: modules.general(ctx.$axios),
        auth: modules.authentication(ctx.$axios),
        create: modules.create(ctx.$axios),
        messages: modules.messages(ctx.$axios),
        notifications: modules.notifications(ctx.$axios),
        info: modules.info(ctx.$axios),
    })
}

declare module 'vue/types/vue' {
    interface Vue {
        $api: Api.Services
    }
}
declare module '@nuxt/types' {
    interface Context {
        $api: Api.Services
    }
}

export default plugin
