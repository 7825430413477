import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { AxiosError } from 'axios'
import endpoints from '@/api/endpoints/user'

export default (AxiosInstance: NuxtAxiosInstance) => ({
    profile: () => AxiosInstance.$get(endpoints.profile.path).catch((e: AxiosError) => e.response?.data),
    acceptTerms: (id: number) =>
        AxiosInstance.$get(endpoints.acceptTerms.path.replace('_ID_', String(id))).catch(
            (e: AxiosError) => e.response?.data
        ),
    updateAvatar: (file: File) => {
        const formData = new FormData()

        formData.append('image', file, file.name)

        return AxiosInstance.$post(endpoints.updateAvatar.path, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }).catch((e: AxiosError) => {
            return e.response?.data
        })
    },
    updateProfile: (userId: string, body: object) => {
        return AxiosInstance.$put(endpoints.updateProfile.path.replace('_ID_', userId), body).catch(
            (e: AxiosError) => e.response?.data
        )
    },
    updateRRSSPassword: (body: object) => {
        return AxiosInstance.$patch(endpoints.updatePassword.rrss, body).catch(
            (e: AxiosError) => e.response?.data
        )
    },
    deleteAccount: (userId: string) => {
        return AxiosInstance.$delete(endpoints.deleteAccount.path.replace('_ID_', userId)).catch(
            (e: AxiosError) => e.response?.data
        )
    },
    verifyAccount: (code: string) => {
        return AxiosInstance.$post(endpoints.verifyAccount.path, { code }).catch(
            (e: AxiosError) => e.response?.data
        )
    },
    requestAccountVerification: () => {
        return AxiosInstance.$get(endpoints.requestAccountVerification.path).catch(
            (e: AxiosError) => e.response?.data
        )
    },
    emailPreferences: () => {
        return AxiosInstance.$get(endpoints.emailPreferences.path).catch((e: AxiosError) => e.response?.data)
    },
    webPreferences: () => {
        return AxiosInstance.$get(endpoints.webPreferences.path).catch((e: AxiosError) => e.response?.data)
    },
    updatePreferences: (query: object, payload: object) => {
        return AxiosInstance.$post(endpoints.emailPreferences.path, payload, { params: query }).catch(
            (e: AxiosError) => e.response?.data
        )
    },
})
