import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2b86290c = () => interopDefault(import('../pages/acerca-de.vue' /* webpackChunkName: "pages/acerca-de" */))
const _56df6996 = () => interopDefault(import('../pages/aviso-legal.vue' /* webpackChunkName: "pages/aviso-legal" */))
const _bdfd32da = () => interopDefault(import('../pages/ayuda.vue' /* webpackChunkName: "pages/ayuda" */))
const _1f656f48 = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _1055ffba = () => interopDefault(import('../pages/cambiar-contrasena.vue' /* webpackChunkName: "pages/cambiar-contrasena" */))
const _65ded172 = () => interopDefault(import('../views/CategoriesBridge.vue' /* webpackChunkName: "" */))
const _60d556a2 = () => interopDefault(import('../pages/cobee.vue' /* webpackChunkName: "pages/cobee" */))
const _119b0dca = () => interopDefault(import('../views/Homepage.vue' /* webpackChunkName: "" */))
const _a828a98e = () => interopDefault(import('../pages/compartir/index.vue' /* webpackChunkName: "pages/compartir/index" */))
const _268da346 = () => interopDefault(import('../pages/contacto.vue' /* webpackChunkName: "pages/contacto" */))
const _543b7814 = () => interopDefault(import('../pages/contrasena-actualizada-exitosamente.vue' /* webpackChunkName: "pages/contrasena-actualizada-exitosamente" */))
const _677ccde8 = () => interopDefault(import('../pages/cookies.vue' /* webpackChunkName: "pages/cookies" */))
const _0fae2ae7 = () => interopDefault(import('../pages/cupones/index.vue' /* webpackChunkName: "pages/cupones/index" */))
const _9b64e020 = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _4b4a6273 = () => interopDefault(import('../pages/dashboard/index/index.vue' /* webpackChunkName: "pages/dashboard/index/index" */))
const _46688fde = () => interopDefault(import('../pages/dashboard/index/actividad.vue' /* webpackChunkName: "pages/dashboard/index/actividad" */))
const _3ac3691c = () => interopDefault(import('../pages/dashboard/index/ajustes.vue' /* webpackChunkName: "pages/dashboard/index/ajustes" */))
const _45ef7588 = () => interopDefault(import('../pages/dashboard/index/chats.vue' /* webpackChunkName: "pages/dashboard/index/chats" */))
const _5132e93a = () => interopDefault(import('../pages/dashboard/index/descuentos.vue' /* webpackChunkName: "pages/dashboard/index/descuentos" */))
const _5e72118d = () => interopDefault(import('../pages/dashboard/index/guardados.vue' /* webpackChunkName: "pages/dashboard/index/guardados" */))
const _2cf72804 = () => interopDefault(import('../pages/dashboard/index/monedas.vue' /* webpackChunkName: "pages/dashboard/index/monedas" */))
const _a3d94ef8 = () => interopDefault(import('../pages/dashboard/index/premios.vue' /* webpackChunkName: "pages/dashboard/index/premios" */))
const _1aee0d8c = () => interopDefault(import('../pages/dashboard/index/sorteos.vue' /* webpackChunkName: "pages/dashboard/index/sorteos" */))
const _8f69f1cc = () => interopDefault(import('../pages/eventos/index.vue' /* webpackChunkName: "pages/eventos/index" */))
const _361e1e66 = () => interopDefault(import('../pages/expirados.vue' /* webpackChunkName: "pages/expirados" */))
const _84f17950 = () => interopDefault(import('../pages/extension.vue' /* webpackChunkName: "pages/extension" */))
const _67abc8c0 = () => interopDefault(import('../pages/extension-desinstalada.vue' /* webpackChunkName: "pages/extension-desinstalada" */))
const _65a7c36f = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _3baa3f09 = () => interopDefault(import('../pages/foros/index.vue' /* webpackChunkName: "pages/foros/index" */))
const _6d23a23f = () => interopDefault(import('../pages/gratis.vue' /* webpackChunkName: "pages/gratis" */))
const _d21ea808 = () => interopDefault(import('../pages/inicio-sin-contrasena.vue' /* webpackChunkName: "pages/inicio-sin-contrasena" */))
const _15ce9a02 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _6f930e11 = () => interopDefault(import('../views/InstallExtension.vue' /* webpackChunkName: "" */))
const _3262b405 = () => interopDefault(import('../pages/mensajeria/index.vue' /* webpackChunkName: "pages/mensajeria/index" */))
const _89b062fe = () => interopDefault(import('../pages/notificaciones.vue' /* webpackChunkName: "pages/notificaciones" */))
const _42b7b461 = () => interopDefault(import('../pages/novedades/index.vue' /* webpackChunkName: "pages/novedades/index" */))
const _6036d7fe = () => interopDefault(import('../pages/nueva-contrasena.vue' /* webpackChunkName: "pages/nueva-contrasena" */))
const _3ce01f04 = () => interopDefault(import('../pages/ofertas/index.vue' /* webpackChunkName: "pages/ofertas/index" */))
const _0a4ce79c = () => interopDefault(import('../pages/olvide-mi-contrasena.vue' /* webpackChunkName: "pages/olvide-mi-contrasena" */))
const _ed242aaa = () => interopDefault(import('../pages/politicas-de-privacidad.vue' /* webpackChunkName: "pages/politicas-de-privacidad" */))
const _6ae7584a = () => interopDefault(import('../views/Preferences.vue' /* webpackChunkName: "" */))
const _75eabb70 = () => interopDefault(import('../pages/prensa.vue' /* webpackChunkName: "pages/prensa" */))
const _ec6c3dac = () => interopDefault(import('../pages/registro.vue' /* webpackChunkName: "pages/registro" */))
const _3fc05d48 = () => interopDefault(import('../pages/reglas-de-comunidad.vue' /* webpackChunkName: "pages/reglas-de-comunidad" */))
const _37128042 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _5041b911 = () => interopDefault(import('../pages/sitemap/index.vue' /* webpackChunkName: "pages/sitemap/index" */))
const _53696ed4 = () => interopDefault(import('../pages/sitemap/index/index.vue' /* webpackChunkName: "pages/sitemap/index/index" */))
const _3a66d407 = () => interopDefault(import('../pages/sitemap/index/promociones/index.vue' /* webpackChunkName: "pages/sitemap/index/promociones/index" */))
const _1113d07d = () => interopDefault(import('../pages/sitemap/index/tiendas/index.vue' /* webpackChunkName: "pages/sitemap/index/tiendas/index" */))
const _38b032bf = () => interopDefault(import('../pages/sitemap/index/promociones/_slug.vue' /* webpackChunkName: "pages/sitemap/index/promociones/_slug" */))
const _8fe6f23a = () => interopDefault(import('../pages/subscribe.vue' /* webpackChunkName: "pages/subscribe" */))
const _3a3486ac = () => interopDefault(import('../pages/unsubscribe.vue' /* webpackChunkName: "pages/unsubscribe" */))
const _5ccbf7fe = () => interopDefault(import('../pages/verificar-cuenta.vue' /* webpackChunkName: "pages/verificar-cuenta" */))
const _4c317b41 = () => interopDefault(import('../pages/compartir/chat.vue' /* webpackChunkName: "pages/compartir/chat" */))
const _3060f107 = () => interopDefault(import('../pages/compartir/cupon/index.vue' /* webpackChunkName: "pages/compartir/cupon/index" */))
const _227b02ce = () => interopDefault(import('../pages/compartir/finalizado.vue' /* webpackChunkName: "pages/compartir/finalizado" */))
const _04004c5d = () => interopDefault(import('../pages/compartir/oferta/index.vue' /* webpackChunkName: "pages/compartir/oferta/index" */))
const _c8fc9b1e = () => interopDefault(import('../pages/compartir/sorteo.vue' /* webpackChunkName: "pages/compartir/sorteo" */))
const _ee1c83f0 = () => interopDefault(import('../views/StoresBridge.vue' /* webpackChunkName: "" */))
const _f7ed5388 = () => interopDefault(import('../pages/compartir/cupon/crear.vue' /* webpackChunkName: "pages/compartir/cupon/crear" */))
const _57a8b192 = () => interopDefault(import('../pages/compartir/oferta/crear.vue' /* webpackChunkName: "pages/compartir/oferta/crear" */))
const _47eb6490 = () => interopDefault(import('../views/StoreMask.vue' /* webpackChunkName: "" */))
const _be89b6ac = () => interopDefault(import('../pages/blog/_category/index.vue' /* webpackChunkName: "pages/blog/_category/index" */))
const _9a42b6b8 = () => interopDefault(import('../views/DiscountDetails.vue' /* webpackChunkName: "" */))
const _501b4070 = () => interopDefault(import('../pages/dashboard/_user.vue' /* webpackChunkName: "pages/dashboard/_user" */))
const _7219f04b = () => interopDefault(import('../pages/dashboard/_user/index.vue' /* webpackChunkName: "pages/dashboard/_user/index" */))
const _3c8d71b6 = () => interopDefault(import('../pages/dashboard/_user/actividad.vue' /* webpackChunkName: "pages/dashboard/_user/actividad" */))
const _03d7d314 = () => interopDefault(import('../pages/dashboard/_user/chats.vue' /* webpackChunkName: "pages/dashboard/_user/chats" */))
const _1faa4262 = () => interopDefault(import('../pages/dashboard/_user/descuentos.vue' /* webpackChunkName: "pages/dashboard/_user/descuentos" */))
const _92d7345c = () => interopDefault(import('../pages/eventos/_slug.vue' /* webpackChunkName: "pages/eventos/_slug" */))
const _39f39dc1 = () => interopDefault(import('../pages/foros/_slug.vue' /* webpackChunkName: "pages/foros/_slug" */))
const _8fc1920c = () => interopDefault(import('../views/DiscountMask.vue' /* webpackChunkName: "" */))
const _41011319 = () => interopDefault(import('../pages/novedades/_slug.vue' /* webpackChunkName: "pages/novedades/_slug" */))
const _6cf8488f = () => interopDefault(import('../pages/blog/_category/_article.vue' /* webpackChunkName: "pages/blog/_category/_article" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/acerca-de",
    component: _2b86290c,
    name: "acerca-de"
  }, {
    path: "/aviso-legal",
    component: _56df6996,
    name: "aviso-legal"
  }, {
    path: "/ayuda",
    component: _bdfd32da,
    name: "ayuda"
  }, {
    path: "/blog",
    component: _1f656f48,
    name: "blog"
  }, {
    path: "/cambiar-contrasena",
    component: _1055ffba,
    name: "cambiar-contrasena"
  }, {
    path: "/categorias",
    component: _65ded172,
    name: "categorias"
  }, {
    path: "/cobee",
    component: _60d556a2,
    name: "cobee"
  }, {
    path: "/comentados",
    component: _119b0dca,
    name: "index-comentados"
  }, {
    path: "/compartir",
    component: _a828a98e,
    name: "compartir"
  }, {
    path: "/contacto",
    component: _268da346,
    name: "contacto"
  }, {
    path: "/contrasena-actualizada-exitosamente",
    component: _543b7814,
    name: "contrasena-actualizada-exitosamente"
  }, {
    path: "/cookies",
    component: _677ccde8,
    name: "cookies"
  }, {
    path: "/cupones",
    component: _0fae2ae7,
    name: "cupones"
  }, {
    path: "/dashboard",
    component: _9b64e020,
    children: [{
      path: "",
      component: _4b4a6273,
      name: "dashboard-index"
    }, {
      path: "actividad",
      component: _46688fde,
      name: "dashboard-index-actividad"
    }, {
      path: "ajustes",
      component: _3ac3691c,
      name: "dashboard-index-ajustes"
    }, {
      path: "chats",
      component: _45ef7588,
      name: "dashboard-index-chats"
    }, {
      path: "descuentos",
      component: _5132e93a,
      name: "dashboard-index-descuentos"
    }, {
      path: "guardados",
      component: _5e72118d,
      name: "dashboard-index-guardados"
    }, {
      path: "monedas",
      component: _2cf72804,
      name: "dashboard-index-monedas"
    }, {
      path: "premios",
      component: _a3d94ef8,
      name: "dashboard-index-premios"
    }, {
      path: "sorteos",
      component: _1aee0d8c,
      name: "dashboard-index-sorteos"
    }]
  }, {
    path: "/eventos",
    component: _8f69f1cc,
    name: "eventos"
  }, {
    path: "/expirados",
    component: _361e1e66,
    name: "expirados"
  }, {
    path: "/extension",
    component: _84f17950,
    name: "extension"
  }, {
    path: "/extension-desinstalada",
    component: _67abc8c0,
    name: "extension-desinstalada"
  }, {
    path: "/faq",
    component: _65a7c36f,
    name: "faq"
  }, {
    path: "/foros",
    component: _3baa3f09,
    name: "foros"
  }, {
    path: "/gratis",
    component: _6d23a23f,
    name: "gratis"
  }, {
    path: "/inicio-sin-contrasena",
    component: _d21ea808,
    name: "inicio-sin-contrasena"
  }, {
    path: "/login",
    component: _15ce9a02,
    name: "login"
  }, {
    path: "/lp",
    component: _6f930e11,
    name: "lp"
  }, {
    path: "/mensajeria",
    component: _3262b405,
    name: "mensajeria"
  }, {
    path: "/notificaciones",
    component: _89b062fe,
    name: "notificaciones"
  }, {
    path: "/novedades",
    component: _42b7b461,
    name: "novedades"
  }, {
    path: "/nueva-contrasena",
    component: _6036d7fe,
    name: "nueva-contrasena"
  }, {
    path: "/nuevos",
    component: _119b0dca,
    name: "index-nuevos"
  }, {
    path: "/ofertas",
    component: _3ce01f04,
    name: "ofertas"
  }, {
    path: "/olvide-mi-contrasena",
    component: _0a4ce79c,
    name: "olvide-mi-contrasena"
  }, {
    path: "/politicas-de-privacidad",
    component: _ed242aaa,
    name: "politicas-de-privacidad"
  }, {
    path: "/preferencias",
    component: _6ae7584a,
    name: "preferences"
  }, {
    path: "/prensa",
    component: _75eabb70,
    name: "prensa"
  }, {
    path: "/registro",
    component: _ec6c3dac,
    name: "registro"
  }, {
    path: "/reglas-de-comunidad",
    component: _3fc05d48,
    name: "reglas-de-comunidad"
  }, {
    path: "/search",
    component: _37128042,
    name: "search"
  }, {
    path: "/sitemap",
    component: _5041b911,
    children: [{
      path: "",
      component: _53696ed4,
      name: "sitemap-index"
    }, {
      path: "promociones",
      component: _3a66d407,
      name: "sitemap-index-promociones"
    }, {
      path: "tiendas",
      component: _1113d07d,
      name: "sitemap-index-tiendas"
    }, {
      path: "promociones/:slug?",
      component: _38b032bf,
      name: "sitemap-index-promociones-slug"
    }]
  }, {
    path: "/subscribe",
    component: _8fe6f23a,
    name: "subscribe"
  }, {
    path: "/unsubscribe",
    component: _3a3486ac,
    name: "unsubscribe"
  }, {
    path: "/verificar-cuenta",
    component: _5ccbf7fe,
    name: "verificar-cuenta"
  }, {
    path: "/compartir/chat",
    component: _4c317b41,
    name: "compartir-chat"
  }, {
    path: "/compartir/cupon",
    component: _3060f107,
    name: "compartir-cupon"
  }, {
    path: "/compartir/finalizado",
    component: _227b02ce,
    name: "compartir-finalizado"
  }, {
    path: "/compartir/oferta",
    component: _04004c5d,
    name: "compartir-oferta"
  }, {
    path: "/compartir/sorteo",
    component: _c8fc9b1e,
    name: "compartir-sorteo"
  }, {
    path: "/cupones-descuento/",
    component: _ee1c83f0,
    name: "marcas"
  }, {
    path: "/compartir/cupon/crear",
    component: _f7ed5388,
    name: "compartir-cupon-crear"
  }, {
    path: "/compartir/oferta/crear",
    component: _57a8b192,
    name: "compartir-oferta-crear"
  }, {
    path: "/",
    component: _119b0dca,
    name: "index"
  }, {
    path: "/go/marcas/:id",
    component: _47eb6490,
    name: "go-store-slug"
  }, {
    path: "/blog/:category",
    component: _be89b6ac,
    name: "blog-category"
  }, {
    path: "/categorias/:slug",
    component: _65ded172,
    name: "categorias-slug"
  }, {
    path: "/cupones-descuento/:slug",
    component: _ee1c83f0,
    name: "marcas-slug"
  }, {
    path: "/cupones/:slug",
    component: _9a42b6b8,
    name: "cupones-slug"
  }, {
    path: "/dashboard/:user",
    component: _501b4070,
    children: [{
      path: "",
      component: _7219f04b,
      name: "dashboard-user"
    }, {
      path: "actividad",
      component: _3c8d71b6,
      name: "dashboard-user-actividad"
    }, {
      path: "chats",
      component: _03d7d314,
      name: "dashboard-user-chats"
    }, {
      path: "descuentos",
      component: _1faa4262,
      name: "dashboard-user-descuentos"
    }]
  }, {
    path: "/eventos/:slug",
    component: _92d7345c,
    name: "eventos-slug"
  }, {
    path: "/foros/:slug",
    component: _39f39dc1,
    name: "foros-slug"
  }, {
    path: "/go/:id",
    component: _8fc1920c,
    name: "go-id"
  }, {
    path: "/goprize/:hash",
    component: _8fc1920c,
    name: "goprize-hash"
  }, {
    path: "/lp/:slug",
    component: _6f930e11,
    name: "lp-slug"
  }, {
    path: "/novedades/:slug",
    component: _41011319,
    name: "novedades-slug"
  }, {
    path: "/ofertas/:slug",
    component: _9a42b6b8,
    name: "ofertas-slug"
  }, {
    path: "/open/:slug",
    component: _8fc1920c,
    name: "open-slug"
  }, {
    path: "/blog/:category/:article",
    component: _6cf8488f,
    name: "blog-category-article"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
